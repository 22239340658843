import { createApp } from "vue";

import Loader from "./../src/components/shared/loader/FingerPrint.vue";
import SurePopUp from "./../src/components/shared/popup/PopUp.vue";
import AlertPopUp from "./../src/components/shared/popup/Alert.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import DatePicker from "vue-datepicker-next";
import "vue-datepicker-next/index.css";
import SelectPicker from "./components/shared/Select";
const app = createApp({});

app.component("loader", Loader);
app.component("surepopup", SurePopUp);
app.component("alertpopup", AlertPopUp);
app.component("ValidationProvider", ValidationProvider);
app.component("ValidationObserver", ValidationObserver);
app.component("DatePicker", DatePicker);
app.component("select-picker", SelectPicker);
