const mutations = {
    UPDATE_BIRTHDAY_USER_DETAILS(state, user_detail) {
        state.birthdayUserDetail = user_detail;
    },

    UPDATE_BIRTHDAY_USER_DETAILS_LOADED(
        state: { isBirthdayDetailLoaded },
        STATUS: boolean,
    ) {
        state.isBirthdayDetailLoaded = STATUS;
    },

    UPDATE_ANNIVERSARY_USER_DETAILS(state, user_detail) {
        state.anniversaryUserDetail = user_detail;
    },

    UPDATE_ANNIVERSARY_USER_DETAILS_LOADED(
        state: { isAnniversaryDetailLoaded },
        STATUS: boolean,
    ) {
        state.isAnniversaryDetailLoaded = STATUS;
    },
    ADD_NEW_BIRTHDAY_USER(state, user_detail) {
        const result = state.birthdayUserDetail.findIndex(function (element) {
            return element.user_id === user_detail.user_id;
        });
        if (result !== -1) {
            state.birthdayUserDetail[result].DOB = user_detail.DOB;
            state.birthdayUserDetail[result].first_name =
                user_detail.first_name;
            state.birthdayUserDetail[result].profile_image =
                user_detail.profile_image;
            state.birthdayUserDetail[result].last_name = user_detail.last_name;
        } else {
            state.birthdayUserDetail.push(user_detail);
        }
        const birthday = state.birthdayUserDetail;
        birthday.sort(function (a, b) {
            const nameA = a.first_name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.first_name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            // names must be equal
            return 0;
        });
        state.birthdayUserDetail = birthday;
    },
    REMOVE_NEW_BIRTHDAY_USER(state, user_detail) {
        const filtered = state.birthdayUserDetail.filter(function (element) {
            return user_detail.user_id !== element.user_id;
        });
        state.birthdayUserDetail = filtered;
    },
    ADD_NEW_ANNIVERSARY_USER(state, user_detail) {
        const result = state.anniversaryUserDetail.findIndex(
            function (element) {
                return element.user_id === user_detail.user_id;
            },
        );

        if (result !== -1) {
            state.anniversaryUserDetail[result].working_year =
                user_detail.working_year;
            state.anniversaryUserDetail[result].first_name =
                user_detail.first_name;
            state.anniversaryUserDetail[result].profile_image =
                user_detail.profile_image;
            state.anniversaryUserDetail[result].last_name =
                user_detail.last_name;
        } else {
            state.anniversaryUserDetail.push(user_detail);
        }
        const anniversary = state.anniversaryUserDetail;
        anniversary.sort(function (a, b) {
            const nameA = a.first_name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.first_name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            // names must be equal
            return 0;
        });
        state.anniversaryUserDetail = anniversary;
    },
    REMOVE_NEW_ANNIVERSARY_USER(state, user_detail) {
        const filtered = state.anniversaryUserDetail.filter(function (element) {
            return user_detail.user_id !== element.user_id;
        });
        state.anniversaryUserDetail = filtered;
    },

    UPDATE_UPCOMING_BIRTHDAY_USER_DETAILS_LOADED(
        state: { isUpcomingBirthdayLoaded },
        STATUS: boolean,
    ) {
        state.isUpcomingBirthdayLoaded = STATUS;
    },

    UPDATE_UPCOMING_BIRTHDAY_USER_DETAILS(state, user_detail) {
        state.upcomingBirthdayUserDetail = user_detail;
    },
};

export default mutations;
