const getters = {
    getBirthdayUserDetailLoaded: (state) => {
        return state.isBirthdayDetailLoaded;
    },
    getBirthdayUserDetail: (state) => {
        return state.birthdayUserDetail;
    },
    getAnniversaryUserDetailLoaded: (state) => {
        return state.isAnniversaryDetailLoaded;
    },
    getAnniversaryUserDetail: (state) => {
        return state.anniversaryUserDetail;
    },
};

export default getters;
