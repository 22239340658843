<template>
    <svg
        width="26"
        height="24"
        viewBox="0 0 26 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M25.0078 17.4419L19.1939 23.2558L15.1241 19.186L16.8683 17.4419L19.1939 19.7674L23.2636 15.6977L25.0078 17.4419ZM12.9148 20.814C12.4497 20.9302 12.1008 20.9302 11.6357 20.9302C6.51944 20.9302 2.33339 16.7442 2.33339 11.6279C2.33339 6.51163 6.51944 2.32558 11.6357 2.32558C16.752 2.32558 20.938 6.51163 20.938 11.6279C20.938 12.093 20.938 12.4419 20.8218 12.907C21.6357 13.0233 22.3334 13.2558 23.0311 13.6047C23.1473 12.907 23.2636 12.3256 23.2636 11.6279C23.2636 5.23256 18.0311 0 11.6357 0C5.24037 0 0.0078125 5.23256 0.0078125 11.6279C0.0078125 18.0233 5.24037 23.2558 11.6357 23.2558C12.3334 23.2558 13.0311 23.1395 13.6125 23.0233C13.2636 22.4419 13.0311 21.6279 12.9148 20.814M15.8218 14.0698L12.2171 11.9767V5.81395H10.4729V12.7907L14.5427 15.2326C14.8915 14.7674 15.3566 14.4186 15.8218 14.0698V14.0698Z"
            fill="#424242"
        />
    </svg>
</template>

<script setup></script>
