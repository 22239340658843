<template>
    <div class="user-info dropdown d-flex align-items-center">
        <!-- dropdown trigger -->
        <a href="javascript:" role="button" id="userDropdownTrigger" class="d-flex align-items-center"
            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div class="user-thumb rounded">
                <img src="@/assets/img/Unknown-Man.png" alt="user-avatar" class="fit-img" v-if="profile_image === '' ||
                    profile_image === null ||
                    profile_image === undefined
                    " />
                <img :src="profile_image" alt="user-avatar" class="fit-img" v-else />
            </div>
            <div class="greet-text fw-500">
                {{ fullName }}
                <span>{{ designationName }}</span>
            </div>
            <img height="50" width="16" src="@/assets/img/dashboard-img/arrow-down-icon.svg" alt="arrow-down-icon"
                class="drop-arrow-icon" style="margin-left: 10px" />
        </a>
        <!-- dropdowns -->
        <div class="dropdown-menu" aria-labelledby="userDropdownTrigger" x-placement="bottom-start">
            <router-link v-show="!isSuperAdmin" :to="{
                name: 'employee_profile',
            }" class="dropdown-item">
                Profile
            </router-link>

            <router-link :to="{ name: 'security' }" class="dropdown-item">
                Change password
            </router-link>
            <a class="dropdown-item" href="javascript:" @click.prevent="logout">
                Logout
            </a>
        </div>
    </div>
</template>

<script>
import AuthenticationService from "./../../services/auth";
import AlertPopUpMixin from "./../mixins/AlertPopUp";

const authenticationservice = new AuthenticationService();
export default {
    mixins: [AlertPopUpMixin],

    data() {
        return {
            isSuperAdmin: this.$store.getters['AUTH/getSuperAdminStatus'],
        };
    },
    mounted() {
        const userDetails = this.$store.getters["AUTH/getUserDetails"];
    },

    computed: {
        capability_lists() {
            let capabilities = this.$store.getters["AUTH/getUserCapabilities"];
            return capabilities;
        },

        profile_image() {
            const detail = this.$store.getters["AUTH/getUserDetails"];
            return detail.profile_image;
        },

        fullName() {
            const detail = this.$store.getters["AUTH/getUserDetails"];
            return detail.first_name + " " + detail.last_name;
        },

        designationName() {
            const detail = this.$store.getters["AUTH/getUserDetails"];
            return detail.role?.name;
        },
        user_id() {
            const detail = this.$store.getters["AUTH/getUserDetails"];
            return detail.user_id;
        },
    },

    methods: {
        logout() {
            authenticationservice
                .logout()
                .then((response) => {
                    this.$router.push({
                        path: "/login",
                    });
                })
                .catch((err) => {
                    this.alertError("Could Not Logout.");
                    // alert("Could Not Logout");
                });
        },
    },
};
</script>

<style lang="scss">
.user-info {
    .dropdown-item {
        padding: 10px;
        display: flex;
        align-items: center;

        @include media(large) {
            padding: 10px 20px;
        }

        &:hover {
            background: transparentize($color: $primaryColor, $amount: 0.95);
            color: $primaryColor;
        }
    }
}
</style>
