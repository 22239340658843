const mutations = {
    UPDATE_UPLOAD_SIZE_STATUS(
        state: { isTotalSizeUploadLoaded; totalSizeUploaded },
        data,
    ) {
        state.isTotalSizeUploadLoaded = data.status;
        state.totalSizeUploaded = data.size;
    },

    UPDATE_TOTAL_ALLOCATED_SIZE_STATUS(
        state: { isAllowedSizeLoaded; totalAllowedSize },
        data,
    ) {
        state.isAllowedSizeLoaded = data.status;
        state.totalAllowedSize = data.size;
    },

    RESET_TO_INITIAL_STATE(state) {
        state.isTotalSizeUploadLoaded = false;
        state.totalSizeUploaded = 0;
        state.isAllowedSizeLoaded = false;
        state.totalAllowedSize = 200;
    },
};

export default mutations;
