import axios from "axios";
import Router from "../router";
import AuthenticationService from "./auth";
import store from "./../store";
import { eventBus } from "@/main";

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
});
const identityService =
    instance.defaults.baseURL + process.env.VUE_APP_IDENTITY_SERVICE_BASE_URL;
const attendanceService =
    instance.defaults.baseURL + process.env.VUE_APP_ATTENDANCE_SERVICE_BASE_URL;
const companyService =
    instance.defaults.baseURL + process.env.VUE_APP_COMPANY_SERVICE_BASE_URL;
const notificationService =
    instance.defaults.baseURL +
    process.env.VUE_APP_NOTIFICATION_SERVICE_BASE_URL;
const employeeService =
    instance.defaults.baseURL + process.env.VUE_APP_EMPLOYEE_SERVICE_BASE_URL;
const eventService =
    instance.defaults.baseURL + process.env.VUE_APP_EVENT_SERVICE_BASE_URL;
const leaveService =
    instance.defaults.baseURL + process.env.VUE_APP_LEAVE_SERVICE_BASE_URL;
const aggregationService =
    instance.defaults.baseURL +
    process.env.VUE_APP_AGGREGATION_SERVICE_BASE_URL;
const jobService =
    instance.defaults.baseURL + process.env.VUE_APP_JOB_SERVICE_BASE_URL;
const authenticationservice = new AuthenticationService();

// var pendingRequests = 0;

instance.interceptors.request.use(
    async (config) => {
        // pendingRequests++;
        // store.dispatch('LOADER/addLoaderVisibility', true);
        config.headers["Authorization"] = 'Bearer ';

        const token = localStorage.getItem("access_token");
        const user_id = localStorage.getItem("user_id");
        if (token && user_id) {
            config.headers["Authorization"] = "Bearer " + token;
            config.headers["X-User-UUID"] = user_id;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);
let isAlreadyFetchingAccessToken = false;
let subscribers = [];
function onAccessTokenFetched(access_token) {
    subscribers = subscribers.filter((callback) => callback(access_token));
}
function addSubscriber(callback) {
    subscribers.push(callback);
}

function handleEdgeCaseLogout(){
    const feature_banner = localStorage.getItem("feature_banner");
    const birthday_banner = localStorage.getItem("birthday");
    localStorage.clear();
    if (feature_banner != null) {
        localStorage.setItem("feature_banner", feature_banner);
    }
    if (birthday_banner != null) {
        localStorage.setItem("birthday", birthday_banner);
    }
    store.dispatch("AUTH/setDefaultAuthState");
    store.dispatch("ATTENDANCE/setDefaultAttendanceState");
    store.dispatch("COMPANY/setDefaultCompanyState");
    store.dispatch("NOTICE/setDefaultNoticeState");
    store.dispatch("EMPLOYEE/setDefaultState");
    store.dispatch("setDefaultState");
    eventBus.$emit("displayInitialLoader", false);
    Router.push("/login").catch();
}

instance.interceptors.response.use(
    function (response) {
        return response;
    },
    async function (error) {
        const message = error.response.data.message;
        const statusCode = error.response.status;
        const originalRequest = error.config;
        if (message === "Unauthorized" && statusCode === 401) {
            const remember_me = localStorage.getItem("remember_option");
            if (remember_me === "true") {
                if (!isAlreadyFetchingAccessToken) {
                    isAlreadyFetchingAccessToken = true;
                    authenticationservice
                        .renewToken()
                        .then((response) => {
                            onAccessTokenFetched(
                                store.getters["AUTH/getAccessToken"],
                            );
                            isAlreadyFetchingAccessToken = false;
                        })
                        .catch((err) => {
                            const feature_banner =
                                localStorage.getItem("feature_banner");
                            const birthday_banner =
                                localStorage.getItem("birthday");
                            localStorage.clear();
                            if (feature_banner != null) {
                                localStorage.setItem(
                                    "feature_banner",
                                    feature_banner,
                                );
                            }
                            if (birthday_banner != null) {
                                localStorage.setItem(
                                    "birthday",
                                    birthday_banner,
                                );
                            }
                            store.dispatch("AUTH/setDefaultAuthState");
                            store.dispatch(
                                "ATTENDANCE/setDefaultAttendanceState",
                            );
                            store.dispatch("COMPANY/setDefaultCompanyState");
                            store.dispatch("NOTICE/setDefaultNoticeState");
                            store.dispatch("EMPLOYEE/setDefaultState");
                            store.dispatch("setDefaultState");
                            eventBus.$emit("displayInitialLoader", false);
                            Router.push("/login").catch();
                        });
                }
                const retryOriginalRequest = new Promise((resolve) => {
                    addSubscriber((access_token) => {
                        originalRequest.headers.Authorization = `Bearer ${access_token}`;
                        resolve(axios(originalRequest));
                    });
                });
                return retryOriginalRequest;
            } 
            else {
                handleEdgeCaseLogout();
            }
        }
        else if(statusCode === 403){
            handleEdgeCaseLogout();
        }
        else {
            return Promise.reject(error);
        }
    },
);

export default class ApiService {
    /**************  Send Email Invitation ****************************/
    public invitation(data: any) {
        // return instance.post(identityService + '/invitation', data);
        return instance.post(identityService + "/invitation", data);
    }

    /************** Get Email From Token *****************************/
    public emailByToken(token: any) {
        // return instance.get(identityService + '/mail', {
        return instance.get(identityService + "/mail", {
            params: {
                token: token,
            },
        });
    }

    /***************** Register Company And admin *********************/
    // public registerCompany(data: any) {
    //     // return instance.post(identityService + '/company', data);
    //     return instance.post(identityService + '/company', data);
    // }

    // *********************************************************************************************************
    // ************************** Identity Service *************************************************************
    // *********************************************************************************************************

    public signup(data: any) {
        //for user signup
        return instance.post(identityService + "/signup", data);
    }

    /************************* Add Employee By Admin  ************************************************************/
    public createEmployee(data: any) {
        return instance.post(identityService + "/users", data);
    }

    /************************* Invite Employee  ************************************************************/
    public inviteEmployee(data: any) {
        return instance.post(identityService + "/invite", data);
    }
    /************************* Invite Employee  ************************************************************/
    public importEmployee(data: any) {
        return instance.post(identityService + "/import/user", data);
    }

    //   /************************* Add Employee By Admin  ************************************************************/
    //    public createEmployee(data: any) {
    //     return instance.post(identityService + '/admin/employee', data);
    // }
    /****************************** Update Employee(Identity Service) ****************************************/
    public updateDetails(user_id: any, data: any) {
        return instance.put(identityService + "/users/" + user_id, data);
    }

    /****************************** Update Employee(Employee Service) ****************************************/
    public updateEmployee(employee_id: any, data: any) {
        return instance.put(employeeService + "/" + employee_id, data);
    }

    /****************************** Get Employee Details ****************************************/
    public getDetails(employeeId: any) {
        return instance.get(identityService + "/employee/" + employeeId);
    }

    /****************************** Get User with handle leave capability  ****************************************/
    public handleLeavesUsers() {
        return instance.get(identityService + "/user/handleleave");
    }

    /****************************** List Current User Capabilities  ****************************************/
    public userCapabilities() {
        return instance.get(identityService + "/user/capabilities");
    }

    /****************************** List Company Employees ****************************************/
    public allEmployees(data: any) {
        return instance.get(employeeService + "", {
            params: {
                status: data.status,
                role_id: data.role_id,
                keyword: data.keyword,
            },
        });
    }

    public allInvitedEmployee() {
        return instance.get(identityService + "/invite");
    }

    public updateStatus(data: any) {
        return instance.put(identityService + "/user/status", data);
    }

    public getPayableUsers(data: any) {
        return instance.get(identityService + "/employees/payable", {
            params: {
                company_id: data.company_id,
                start_date: data.start_date,
                end_date: data.end_date,
            },
        });
    }
    //to update password
    public updatePassword(data: any) {
        return instance.put(identityService + "/password", data);
    }

    //for user signup
    public userSignup(data: any) {
        // return instance.post(identityService + '/signup', data);
        return instance.post(identityService + "/signup", data);
    }

    // *********************************************************************************************************
    // ************************** End Of Identity Service ******************************************************
    // *********************************************************************************************************

    // *********************************************************************************************************
    // ************************** Company Service *************************************************************
    // *********************************************************************************************************
    // public registerCompany(data: any) {
    //     return instance.post('/company', data);
    // }

    /****************************** Add New Setting ****************************************/
    // public saveSetting(data: any) {
    //     return instance.post(companyService + '/setting', data);
    // }

    /****************************** Update Setting  ****************************************/
    // public updateSetting(setting_id: bigint, data: any) {  //setting_id
    //     return instance.put(companyService + '/setting/' + setting_id, data);
    // }

    /****************************** Get Setting Value  ****************************************/
    // public getSettingValue(data: any) {
    //     return instance.get(companyService + '/setting', {
    //         params: {
    //             setting_name: data.setting_name,
    //             // company_id: data.company_id
    //         }
    //     })
    // }

    /************************** Company Details  ***************************************/
    public getCompany(company_id: any) {
        return instance.get(companyService + "/" + company_id);
    }

    /************************** All Company List  ***************************************/
    public getAllCompany(company_data: any) {
        return instance.get(companyService + "/", {
            params: {
                slug: company_data.slug,
            },
        });
    }

    public getAllCompanyList() {
        return instance.get(companyService);
    }

    /************************** All Company List  ***************************************/
    public getCompanyBySlug(slug: any) {
        return instance.get(companyService + "/verify/" + slug);
    }

    /************************** Company Update  ***************************************/
    public updateCompany(company_id: any, data: any) {
        return instance.put(companyService + "/" + company_id, data);
    }

    /************************** Add New Department  *******************************************/
    public addDepartment(data: any) {
        return instance.post(companyService + "/departments", data);
    }

    /************************* Update Department ************************************************/
    public updateDepartment(department_id: any, data: any) {
        return instance.put(
            companyService + "/departments/" + department_id,
            data,
        );
    }

    /************************* Delete Department ************************************************/
    public deleteDepartment(department_id: any) {
        return instance.delete(
            companyService + "/departments/" + department_id,
        );
    }

    /************************** All Company List  ***************************************/
    public getCompanyInvoice(company_id: any) {
        return instance.get(companyService + "/" + company_id + "/invoices");
    }

    public downloadInvoice(data: any) {
        return instance.post(companyService + "/invoices/download", data);
    }
    // *********************************************************************************************************
    // ************************** End Of Company Service *******************************************************
    // *********************************************************************************************************

    // *********************************************************************************************************
    // ************************** Attendance Service *************************************************************
    // *********************************************************************************************************

    /****************************** Check checked in/out status of employee ****************************************/
    public checkStatus(data: any) {
        return instance.get(attendanceService + "/status");
    }

    /****************************** Check In  ****************************************/
    public checkIn(data: object) {
        return instance.post(attendanceService + "/check-in", data);
    }

    /****************************** Check Out ****************************************/
    public checkOut(data: any) {
        return instance.post(attendanceService + "/check-out", data);
    }

    /****************************** Pause ****************************************/
    public takeBreak(data: any) {
        return instance.post(attendanceService + "/pause", data);
    }

    /****************************** Resume ****************************************/
    public stopBreak(data: any) {
        return instance.post(attendanceService + "/resume", data);
    }

    /****************************** Filter Attendance ****************************************/
    public filterAttendance(data: any) {
        return instance.get(attendanceService + "", {
            params: {
                start_date: data.start_date,
                end_date: data.end_date,
                user_id: data.user_id,
                group: data.group,
                today_date: data.today_date,
                groupByMonth: data.groupByMonth,
                groupByYear: data.groupByYear,
                // company_id: data.company_id
            },
        });
    }

    public addAttendance(data: any) {
        return instance.post(attendanceService, data);
    }

    public updateAttendance(attendanceId: string, data: any) {
        return instance.put(attendanceService + "/" + attendanceId, data);
    }

    // *********************************************************************************************************
    // ************************** End Of Attendance Service ****************************************************
    // *********************************************************************************************************

    // *********************************************************************************************************
    // ************************** Event/Announcement Service ***************************************************
    // *********************************************************************************************************

    /****************************** Event Detail  ****************************************/
    public displayEvent(eventId: bigint) {
        return instance.get(eventService + "/" + eventId);
    }

    /****************************** Save Event  ****************************************/
    public saveEvent(data: any) {
        return instance.post(eventService, data);
    }

    /****************************** Edit Event  ****************************************/
    public editEvent(eventId: bigint, data: any) {
        return instance.put(eventService + "/" + eventId, data);
    }

    /****************************** Delete Event  ****************************************/
    public deleteEvent(eventId: bigint) {
        return instance.delete(eventService + "/" + eventId);
    }

    /****************************** Filter Event  ****************************************/
    public filterEvent(data: any) {
        return instance.get(eventService, {
            params: {
                start_date: data.start_date,
                end_date: data.end_date,
                limit: data.limit,
                offset: data.offset,
                keyword: data.keyword,
            },
        });
    }

    /****************************** Search Event  ****************************************/
    public searchEvent(data: any) {
        return instance.get(eventService + "/search", {
            params: {
                // company_id: data.company_id,
                keyword: data.keyword,
            },
        });
    }

    // *********************************************************************************************************
    // ************************** End Of Event/Announcement Service ********************************************
    // *********************************************************************************************************

    // *********************************************************************************************************
    // ************************** Leave Service ********************************************
    // *********************************************************************************************************

    /****************************** List Company Leave Types  ****************************************/
    public getLeaveTypes() {
        return instance.get(leaveService + "/types");
    }

    /****************************** Add New Company Leave Type  ****************************************/
    public storeLeaveType(data: any) {
        return instance.post(leaveService + "/types", data);
    }

    /****************************** Update Leave Type  ****************************************/
    public updateLeaveType(leaveTypeid: bigint, data: any) {
        return instance.put(leaveService + "/types/" + leaveTypeid, data);
    }

    /****************************** Delete Leave Types  ****************************************/
    public deleteLeaveType(leaveTypeid: bigint) {
        return instance.delete(leaveService + "/types/" + leaveTypeid);
    }

    /****************************** Filter Leave  ****************************************/
    public filterLeave(data: any) {
        return instance.get(leaveService + "", {
            params: {
                user_id: data.employee_id,
                start_date: data.start_date,
                end_date: data.end_date,
                today_date: data.today_date,
                type_id: data.type_id,
                assigned_to: data.assigned_to,
                status: data.status,
                limit: data.limit,
                leave_id: data.leave_id,
            },
        });
    }

    /*********************************  Download Leave Document  *************************************/
    public downloadLeaveDocument(data: any) {
        return instance.post(leaveService + "/download", data);
    }

    /****************************** Apply Leave  ****************************************/
    public applyLeave(data: any) {
        return instance.post(leaveService, data);
    }

    /****************************** Get Leave Detail  ****************************************/
    public leaveDetails(leaveId: bigint) {
        return instance.get(leaveService + "/" + leaveId);
    }

    /****************************** Search Leave  ****************************************/
    public searchLeave(data: any) {
        return instance.get(leaveService + "/search", {
            params: {
                keyword: data.keyword,
                // employee_id: data.employee_id,
                // company_id: data.company_id,
            },
        });
    }

    /****************************** Delete Leave Types  ****************************************/
    public deleteLeave(leaveId: string) {
        return instance.delete(leaveService + "/" + leaveId);
    }

    // *********************************************************************************************************
    // ************************** End Of Leave Service ********************************************************
    // *********************************************************************************************************

    // *********************************************************************************************************
    // ************************** Role Service *****************************************************************
    // *********************************************************************************************************

    /****************************** List Capabilities  ****************************************/
    public listOfCapabilities() {
        return instance.get(identityService + "/capabilities");
    }

    /****************************** List Company ROles  ****************************************/
    public listCompanyRoles() {
        return instance.get(identityService + "/roles");
    }

    /****************************** Create new Role  ****************************************/
    public createRole(data: any) {
        return instance.post(identityService + "/roles", data);
    }

    /****************************** Edit Role  ****************************************/
    public editRole(roleId: bigint, data: any) {
        return instance.put(identityService + "/roles/" + roleId, data);
    }

    /****************************** Delete Role  ****************************************/
    public deleteRole(roleId: bigint) {
        return instance.delete(identityService + "/roles/" + roleId);
    }

    // *********************************************************************************************************
    // ************************** End Of Role Service **********************************************************
    // *********************************************************************************************************

    // *********************************************************************************************************
    // ************************** Employee Service *************************************************************
    // *********************************************************************************************************

    /****************************** Employee Details ****************************************/
    public getEmployee(employee_id: any) {
        return instance.get(employeeService + "/" + employee_id);
    }

    /****************************** List Employee Skills ****************************************/
    public getSkills(data: any) {
        return instance.get(employeeService + "/skill", {
            params: {
                employee_id: data.employee_id,
            },
        });
    }

    /****************************** Add New Employee Skill ****************************************/
    public addSkill(data: any) {
        return instance.post(employeeService + "/skill", data);
    }

    /****************************** Delete Skill ****************************************/
    public deleteSkill(id: bigint) {
        return instance.put(employeeService + "/" + id);

        // return instance.delete(employeeService + "/skill/" + id);
    }

    /****************************** List Employee Certificates ****************************************/
    public getCertificates(data: any) {
        return instance.get(employeeService + "/certificate", {
            params: {
                employee_id: data.employee_id,
            },
        });
    }

    /****************************** Add Employee Certificate ****************************************/
    public addCertificate(data: any) {
        return instance.post(employeeService + "/certificate", data);
    }

    /****************************** Delete Certificate ****************************************/
    public deleteCertificate(id: any) {
        return instance.delete(employeeService + "/certificate/" + id);
    }

    /****************************** Get Employee Languages ****************************************/
    public getLanguage(data: any) {
        return instance.get(employeeService + "/language", {
            params: {
                employee_id: data.employee_id,
            },
        });
    }

    /****************************** Add Employee Language ****************************************/
    public addLanguage(data: any) {
        return instance.post(employeeService + "/language", data);
    }

    /****************************** Delete language ****************************************/
    public deleteLanguage(id: bigint) {
        return instance.delete(employeeService + "/language/" + id);
    }

    /****************************** Get Employee Education ****************************************/
    public getEducation(data: any) {
        return instance.get(employeeService + "/education", {
            params: {
                employee_id: data.employee_id,
            },
        });
    }

    /****************************** Add Employee Education ****************************************/
    public addEducation(data: any) {
        return instance.post(employeeService + "/education", data);
    }

    /****************************** Delete Education ****************************************/
    public deleteEducation(id: bigint) {
        return instance.delete(employeeService + "/education/" + id);
    }

    /****************************** Get Employee Emergency ****************************************/
    public getEmergency(data: any) {
        return instance.get(employeeService + "/emergency", {
            params: {
                employee_id: data.employee_id,
            },
        });
    }

    /****************************** Add Employee Emergency ****************************************/
    public addEmergency(data: any) {
        return instance.post(employeeService + "/emergency", data);
    }

    /****************************** Delete Emergency ****************************************/
    public deleteEmergency(id: bigint) {
        return instance.delete(employeeService + "/emergency/" + id);
    }

    /****************************** Create Employee ****************************************/
    public addEmployee(data: any) {
        return instance.post(employeeService + "/", data);
    }

    /****************************** Employee Details ****************************************/
    public detailEmployee(data: any) {
        return instance.get(identityService + "/employee", {
            params: {
                employee_id: data.employee_id,
            },
        });
    }

    /*****************************Update Profile Image ******************************************/
    public updateProfileImage(data: any, id: bigint) {
        return instance.put(employeeService + "/" + id, {
            profile_image: data,
        });
    }

    // *********************************************************************************************************
    // ************************** End Of Employee Service ******************************************************
    // ********************************************************************************************************

    // *********************************************************************************************************
    // ************************** Notification Service ********************************************************
    // ********************************************************************************************************

    public listNotifications() {
        return instance.get(notificationService);
    }

    public markRead(notification: any) {
        return instance.put(notificationService + "/" + notification);
    }

    public readAll() {
        return instance.post(notificationService + "/read_all");
    }

    public sendNotification(data: any) {
        return instance.post(notificationService, data);
    }

    // *********************************************************************************************************
    // ************************** End Of Notification Service ********************************************************
    // ********************************************************************************************************

    public listOfCountries() {
        return instance.get("https://restcountries.eu/rest/v2/all");
    }

    public displayLocation(longitude: number, latitude: number) {
        return instance.get(
            "http://maps.google.com/maps?q=" + longitude + "," + latitude,
        );
    }

    public userDetail(userId: string) {
        return instance.get(identityService + "/users/" + userId);
    }

    public sendVerificationCode(email: string, name: string) {
        return instance.get(identityService + "/emails/verify", {
            params: {
                email: email,
                name: name,
            },
        });
    }

    public verifyVerificationCode(data: { email: string; code: string }) {
        return instance.post(identityService + "/emails/verify", data);
    }

    public registerCompany(data: {
        company_name: string;
        country: string;
        address: string;
        slug: string;
    }) {
        return instance.post(companyService, data);
    }

    public addUser(data: {
        email: string;
        password: string;
        password_confirmation: string;
        first_name: string;
        last_name: string;
        company_id: string;
        role_id: string;
        status: string;
        country: string;
    }) {
        return instance.post(identityService + "/users", data);
    }

    public checkEmail(email: string) {
        return instance.get(identityService + "/emails/unique", {
            params: {
                email: email,
            },
        });
    }

    //Check if user email is same as ammin's email
    public validateEmail(data: any) {
        return instance.post(identityService + "/emails/validate", data);
    }

    public forgotPassword(email: string, company: string) {
        return instance.get(identityService + "/password", {
            params: {
                email: email,
                company: company,
            },
        });
    }

    public forgotSlug(data: any) {
        return instance.post(identityService + "/slug", data);
    }

    public changePassword(data: {
        email: string;
        token: string;
        new_password: string;
        password_confirmation: string;
    }) {
        return instance.post(identityService + "/password", data);
    }

    public employeeDetail(employeeId: string) {
        return instance.get(employeeService + "/" + employeeId);
    }

    public updateLeave(leaveId: string, data: object) {
        return instance.put(leaveService + "/" + leaveId, data);
    }

    public exportEmployee() {
        // return instance.get(employeeService + "/export/user");
        return instance({
            url: employeeService + "/export/user",
            method: "GET",
            responseType: "blob",
        });
    }
    /*****************************Send Support Email ******************************************/
    public createSupport(data: any) {
        return instance.post(aggregationService + "/support", data);
    }

    public downloadEmployeeDocument(data: any) {
        return instance.post(employeeService + "/download", data);
    }

    public uploadPersonalFiles(data: any, type = null, filename = null) {
        return instance.post(employeeService + "/documents", data, {
            headers: {
                "content-type": "multipart/form-data",
            },
            // onUploadProgress(progressEvent) {
            //     // this.$emit('fileProgress', progressEvent);
            //     eventBus.$emit("fileProgress", progressEvent, type, filename);
            // },
        });
    }

    public getFileAndDirectoryLists(current_directory: any) {
        return instance.get(employeeService + "/documents", {
            params: {
                current_directory: current_directory,
            },
        });
    }

    public getPersonalStorageTotalSize() {
        return instance.get(employeeService + "/documents/size");
    }

    public renameDirectory(data) {
        return instance.put(employeeService + "/documents", data);
    }

    public renameFolder(data) {
        return instance.put(employeeService + "/documents/renameFolder", data);
    }


    public renameFile(data) {
        return instance.put(employeeService + "/documents/renameFile", data);
    }


    public deleteDirectory(type, directory, name) {
        return instance.delete(employeeService + "/documents", {
            params: {
                delete_type: type,
                current_directory: directory,
                name: name,
            },
        });
    }

    public getCompanyIntegrations(companyId: string) {
        return instance.get(notificationService + "/integrations", {
            params: {
                company_id: companyId,
            },
        });
    }

    public createDirectory(data) {
        return instance.post(employeeService + "/documents/directories", data);
    }

    public downloadFile(fileDirectory, fileName) {
        return instance.get(employeeService + "/documents/download", {
            params: {
                current_directory: fileDirectory,
                file_name: fileName,
            },
            responseType: "arraybuffer",
        });
    }

    /*****************************Get Occassion ******************************************/

    public getOccasion(data) {
        return instance.post(employeeService + "/occasion", data);
    }

    public postCompanyIntegration(data: any) {
        return instance.post(notificationService + "/integrations", data);
    }
    /*****************************JOB SERVICE ******************************************/
    public createJob(data: any) {
        return instance.post(jobService, data);
    }

    public getCategory() {
        return instance.get(jobService + "/categories/list");
    }
    public getPublishedJob(data: any) {
        return instance.get(jobService + "", {
            params: {
                company_id: data.company_id,
                status: "published",
            },
        });
    }
    public getUnPublishedJob(data: any) {
        return instance.get(jobService + "", {
            params: {
                company_id: data.company_id,
                status: "unpublished",
            },
        });
    }
    public getJobDetailById(data: any) {
        return instance.get(jobService + "/" + data.id, {
            params: {
                company_id: data.company_id,
                status: data.status,
            },
        });
    }
    public updateJobDetailById(data: any) {
        return instance.put(jobService + "/" + data.id, data);
    }
    public copyJob(data: any) {
        return instance.post(jobService + "/copy", data);
    }
    public deleteJob(data: any) {
        return instance.delete(jobService + "/" + data);
    }

    public updateBannerLogo(data: any) {
        return instance.post(jobService + "/companydata", data);
    }
    public getJobApplicant(data: any) {
        return instance.get(jobService + "/application/" + data.id);
    }

    public downloadCV(data: any) {
        return instance.post(jobService + "/download", data, {
            responseType: "arraybuffer",
        });
    }

    public updateApplication(data: any) {
        return instance.post(jobService + "/application", data);
    }
    public getCompanyDetail(data: any) {
        return instance.get(jobService + "/companydata/" + data);
    }

    public filterJob(data: any) {
        return instance.get(jobService + "", {
            params: {
                company_id: data.company_id,
                status: data.status,
                keyword: data.keyword,
            },
        });
    }

    public getDepartment(company_id: any) {
        return instance.get(jobService + "/departments/" + company_id);
    }

    public cancelInvitation(data) {
        return instance.post(identityService + "/invite/cancel", data);
    }

    public resendInvitation(data) {
        return instance.post(identityService + "/invite/resend", data);
    }

    public verifyInvitation(data: any) {
        return instance.get(identityService + "/invite/verify", {
            params: {
                company_id: data.company_id,
                token: data.token,
                email: data.email,
            },
        });
    }
}
