const getters = {
    getCompanyDetailsLoaded: (state) => {
        return state.isCompanyDetailsLoaded;
    },

    getIsCompanySettingsCompleted: (state) => {
        return state.isCompanySettingsCompleted;
    },

    getCompanyDetails: (state) => {
        return state.companyDetails;
    },

    getSettingsStepCompleted: (state) => {
        return state.stepsCompleted;
    },

    getSubDomainComanyId: (state) => {
        return state.subDomainCompanyId;
    },

    getSettingByName: (state) => (settingName) => {
        return state.companyDetails.settings.find((x) => {
            return x.setting_name === settingName;
        });
    },
};

export default getters;
