<template>
    <div
        class="loader full-screen-loader d-flex justify-content-center align-items-center"
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="38"
            viewBox="0 0 38 38"
            stroke="#fff"
        >
            <g fill="none" fill-rule="evenodd">
                <g transform="translate(1 1)" stroke-width="2">
                    <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                    <path
                        d="M36 18c0-9.94-8.06-18-18-18"
                        transform="rotate(329.993 18 18)"
                    >
                        <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 18 18"
                            to="360 18 18"
                            dur="1s"
                            repeatCount="indefinite"
                        />
                    </path>
                </g>
            </g>
        </svg>
    </div>
</template>
<script>
export default {
    components: {},
};
</script>
<style lang="scss">
.loader {
    height: 100vh;
    width: 100vw;
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    // padding-top: 20%;
}
.loaderWrap {
    display: flex;
}
.circle {
    height: 50px;
    width: 50px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circle div {
    border: 5px solid #fff;
    border-radius: 50%;
    display: block;
    animation-name: loadAnim;
    opacity: 0;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    box-sizing: border-box;
}
.circle:nth-child(2) div {
    animation-delay: 0.4s;
}
.circle:nth-child(3) div {
    animation-delay: 0.8s;
}
@keyframes loadAnim {
    0% {
        height: 0px;
        width: 0px;
        opacity: 0;
        border-width: 5px;
    }
    50% {
        opacity: 1;
        height: 30px;
        border-width: 5px;
        width: 30px;
    }
    80%,
    100% {
        height: 0;
        border-width: 0;
        border-color: rgba(255, 255, 255, 0.6);
        width: 0;
        opacity: 0;
    }
}
</style>
