const getters = {
    getSizeUploadedLoaded: (state) => {
        return state.isTotalSizeUploadLoaded;
    },

    getTotalSizeUploaded: (state) => {
        return state.totalSizeUploaded;
    },

    getAllowedSizeLoaded: (state) => {
        return state.isAllowedSizeLoaded;
    },

    getTotalAllowedSize: (state) => {
        return state.totalAllowedSize;
    },
};

export default getters;
