const actions = {
    UPDATEFIRSTLOGINSTATUS({ commit }: any, status: any) {
        commit("UPDATE_FIRST_TIME_COMPANY_LOGIN", status);
    },

    UPDATEPROFILEIMAGE({ commit }: any, image: any) {
        commit("UPDATE_PROFILE_IMAGE", image);
    },

    updateEmployeeListsLoaded({ commit }: any, status) {
        commit("UPDATE_IS_EMPLOYEE_LIST_LOADED", status);
    },

    updateEmployeeLists({ commit }, lists) {
        commit("UPDATE_EMPLOYEE_LISTS", lists);
    },

    pushEmployeeDetail({ commit }, employeeDetail) {
        commit("PUSH_EMPLOYEE", employeeDetail);
    },

    setDefaultState({ commit }) {
        commit("RESET_TO_INITIAL_STATE");
    },
};

export default actions;
