const state = {
    checkInStatus: false,
    checkOutStatus: false,
    breakStatus: false,
    attendanceDetails: {},
    checkInCount:0

};

export default state;
