const actions = {
    setDepartment({ commit }, lists) {
        commit("UPDATE_DEPARTMENT_LIST", lists);
    },
    addDepartment({ commit }, department) {
        commit("PUSH_DEPARTMENT", department);
    },
    updateDepartment({ commit }, data) {
        commit("UPDATE_DEPARTMENT", {
            departmentId: data.department_id,
            department: data.department,
        });
    },
    deleteDepartment({ commit }, departmentId) {
        commit("DELETE_DEPARTMENT", departmentId);
    },
};

export default actions;
