const mutations = {
    UPDATE_ACCESS_TOKEN(state: { accessToken: string }, ACCESSTOKEN: string) {
        state.accessToken = ACCESSTOKEN;
    },

    UPDATE_REFRESH_TOKEN(
        state: { refreshToken: string },
        REFRESHTOKEN: string,
    ) {
        state.refreshToken = REFRESHTOKEN;
    },

    UPDATE_EMPLOYEE_ID(state: { employeeId: string }, EMPLOYEEID: string) {
        state.employeeId = EMPLOYEEID;
    },

    UPDATE_USER_DETAILS(state: { user }, USERDETAILS) {
        state.user = USERDETAILS;
        state.user.skill = USERDETAILS.skill;
        state.user.certification = USERDETAILS.certification;
        state.user.language_known = USERDETAILS.language_known;
        state.user.education = USERDETAILS.education;
        state.user.emergency_contact = USERDETAILS.emergency_contact;
        state.user.note = USERDETAILS.note;
        state.user.document = USERDETAILS.document;
    },

    UPDATE_USER_DATA_LOADED(state: { isUserDataLoaded }, STATUS: boolean) {
        state.isUserDataLoaded = STATUS;
    },

    UPDATE_IS_USER_AUTHENTICATED(state: { isAuthenticated }, STATUS: boolean) {
        state.isAuthenticated = STATUS;
    },

    UPDATE_IS_TOKEN_REFRESHING(state: { isTokenRefreshing }, STATUS: boolean) {
        state.isTokenRefreshing = STATUS;
    },

    RESET_TO_INITAIL_STATE(state) {
        state.accessToken = null;
        state.refreshToken = null;
        state.employeeId = null;
        state.user = {};
        state.isUserDataLoaded = false;
        state.isAuthenticated = false;
    },
    UPDATE_USER_PROFILE_IMAGE(state: { user }, PROFILE_IMAGE) {
        state.user.profile_image = PROFILE_IMAGE;
    },
    UPDATE_USER_PASSWORD_UPDATE_REQUIRED_STATUS(
        state: { user },
        STATUS: boolean,
    ) {
        state.user.password_update_required = STATUS;
    },
    UPDATE_SUPER_ADMIN_STATUS(state: { isUserSuperAdmin }, STATUS: boolean)
    {
        state.isUserSuperAdmin = STATUS;
    },
};

export default mutations;
