const getters = {
    getNoticeLoaded: (state) => {
        return state.isNoticeLoaded;
    },

    getNotices: (state) => {
        return state.notices.sort((a, b) => {
            if (a.start_date < b.start_date) {
                return 1;
            }
            if (a.start_date > b.start_date) {
                return -1;
            }
            return 0;
        });
    },

    getNoticeById: (state) => (id) => {
        return state.notices.find((element) => element.id === id);
    },
};

export default getters;
