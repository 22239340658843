const actions = {
    UpdateStorageDetails({ commit }, data) {
        commit("UPDATE_UPLOAD_SIZE_STATUS", {
            status: data.status,
            size: data.size,
        });
    },

    updateCompanyStorageInfo({ commit }, data) {
        commit("UPDATE_TOTAL_ALLOCATED_SIZE_STATUS", {
            status: data.status,
            size: data.size,
        });
    },

    setDefaultStorageState({ commit }) {
        commit("RESET_TO_INITIAL_STATE");
    },
};

export default actions;
