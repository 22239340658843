<template>
    <div
        class="loader full-screen-loader d-flex justify-content-center align-items-center"
    >
        <div
            class="loader-wrapper-circle d-flex flex-column justify-content-center"
        >
            <skeleton-box
                :width="'500px'"
                :backgroundColor="'#ccc'"
                style="margin-bottom: 15px"
            />
            <skeleton-box
                :width="'600px'"
                :backgroundColor="'#ccc'"
                style="margin-bottom: 15px"
            />
            <skeleton-box
                :width="'600px'"
                :backgroundColor="'#ccc'"
                style="margin-bottom: 30px"
            />
            <skeleton-box
                :width="'500px'"
                :backgroundColor="'#ccc'"
                style="margin-bottom: 15px"
            />
            <skeleton-box
                :width="'600px'"
                :backgroundColor="'#ccc'"
                style="margin-bottom: 15px"
            />
            <skeleton-box :width="'600px'" :backgroundColor="'#ccc'" />
        </div>
    </div>
</template>
<script>
import SkeletonBox from "@/components/skeleton/SkeletonBox.vue";
export default {
    components: {
        "skeleton-box": SkeletonBox,
    },
};
</script>
SkeletonBox
<style lang="scss">
.loader {
    height: 100vh;
    width: 100vw;
    text-align: left;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    &-wrapper-circle {
        @media (max-width: 767px) {
            width: 320px;
        }
        // height: 200px;
        width: 600px;
        background: $white;
        // border-radius: 50%;
        overflow: hidden;
        // border: 5px solid $primaryColor;
    }
}
</style>
