const getters = {
    getDisplayStatus: (state) => {
        return state.display;
    },

    getDisplayTitle: (state) => {
        return state.title;
    },

    getDisplayMessage: (state) => {
        return state.message;
    },

    getDisplayType: (state) => {
        return state.type;
    },
};

export default getters;
