<template>
    <!-- <div class="popup-toastr d-flex align-items-top are-you-sure" :class="{'hide':hide}"> -->
    <div
        class="popup-toastr d-flex align-items-top"
        :class="[type, { 'setup-toastr': !isCompanySettingsCompleted }]"
        v-if="display"
    >
        <div class="popup-inner-wrapper d-flex">
            <div class="text-white message">
                <p>{{ title }} {{ message }}</p>
            </div>
            <a
                href="javascript:"
                class="closebutton"
                @click="closeNotification"
            >
                <Closeicon class="closeicon" />
            </a>
        </div>
    </div>
</template>

<script>
import { closeFlashNotification } from "./../../../flashNotificationMethods.js";
// import checkmark from "../svg/redtickmark";
import closeicon from "../svg/closeIcon";
export default {
    components: {
        // "Checkicon": checkmark,
        Closeicon: closeicon,
    },
    computed: {
        isCompanySettingsCompleted() {
            return this.$store.getters["COMPANY/getIsCompanySettingsCompleted"];
        },
        display() {
            return this.$store.getters["FLASHNOTIFICATION/getDisplayStatus"];
        },
        type() {
            return this.$store.getters["FLASHNOTIFICATION/getDisplayType"];
        },
        title() {
            return this.$store.getters["FLASHNOTIFICATION/getDisplayTitle"];
        },
        message() {
            return this.$store.getters["FLASHNOTIFICATION/getDisplayMessage"];
        },
    },
    methods: {
        closeNotification() {
            closeFlashNotification();
        },
    },
};
</script>

<style lang="scss"></style>
