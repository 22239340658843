const mutations = {
    UPDATE_EMPLOYEE_LISTS(
        state: { isEmployeeListsLoaded; employeeLists },
        data,
    ) {
        state.isEmployeeListsLoaded = data.status;
        state.employeeLists = data.employeeLists;
    },

    UPDATE_EMPLOYEE(state: { employeeLists }, data) {
        const index = state.employeeLists.findIndex((x) => {
            return x.user_id === data.employeeId;
        });
        state.employeeLists[index] = data.employeeData;
    },

    RESET_TO_INITIAL_STATE(state) {
        state.isEmployeeListsLoaded = false;
        state.employeeLists = [];
    },
};

export default mutations;
