const actions = {

    updateCheckInCount({ commit }, count) {
        commit("UPDATE_CHECK_IN_COUNT", count);
    },

    addCheckInStatus({ commit }, status) {
        commit("UPDATE_CHECK_IN_STATUS", status);
    },

    addCheckOutStatus({ commit }, status) {
        commit("UPDATE_CHECK_OUT_STATUS", status);
    },

    addBreakStatus({ commit }, status) {
        commit("UPDATE_BREAK_STATUS", status);
    },

    addAttendanceStatus({ commit }, attendance) {
        if (attendance.checkIn !== null) {
            commit("UPDATE_CHECK_IN_STATUS", true);
        }

        if (attendance.checkOut !== null) {
            commit("UPDATE_CHECK_OUT_STATUS", true);
        }

        if (attendance.breaks.length > 0) {
            const a = attendance.breaks.filter((element) => {
                return element.start_time !== null && element.end_time === null;
            });
            if (a.length > 0) {
                commit("UPDATE_BREAK_STATUS", true);
            } else {
                commit("UPDATE_BREAK_STATUS", false);
            }
            // attendance.breaks.forEach(element => {
            //     if(element.start_time !== null && element.end_time !== null) {
            //         commit('UPDATE_BREAK_STATUS', false);
            //     }
            //     if (element.start_time !== null && element.end_time === null) {
            //         commit('UPDATE_BREAK_STATUS', true)
            //     }
            // });
        }

        //
    },

    addAttendanceDetails({ commit }, status) {
        commit("UPDATE_ATTENDANCE_DETAILS", status);
    },

    setDefaultAttendanceState({ commit }) {
        commit("RESET_TO_INITIAL_STATE");
    },
};

export default actions;
