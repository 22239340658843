<template>
    <div id="main_wrapper" class="theme-default">
        <layout-header />
        <section class="form-section">
            <div class="container form-wrap">
                <div class="row justify-content-center align-items-center">
                    <div class="form-content">
                        <!-- <div class="form-block"> -->
                        <router-view />
                        <!-- </div> -->
                    </div>
                    <div class="shape-wrapper">
                        <div class="top-circle">
                            <svg
                                width="188"
                                height="195"
                                viewBox="0 0 188 195"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M36.2426 194.194C25.4424 184.252 16.7061 172.28 10.5327 158.961C4.35931 145.643 0.869606 131.239 0.262872 116.572C-0.343862 101.904 1.94425 87.2614 6.99657 73.4786C12.0489 59.6957 19.7665 47.043 29.7087 36.2427C39.6509 25.4424 51.623 16.7062 64.9415 10.5328C78.26 4.35937 92.664 0.869693 107.331 0.262954C121.998 -0.343772 136.641 1.94434 150.424 6.99667C164.207 12.049 176.86 19.7666 187.66 29.7088L111.951 111.951L36.2426 194.194Z"
                                    fill="#FFB200"
                                />
                            </svg>
                        </div>
                        <div class="top-triangle">
                            <svg
                                width="207"
                                height="207"
                                viewBox="0 0 207 207"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M207 207L96.7217 99.7398L6.21053e-06 -6.21053e-06L1.52588e-05 207L207 207Z"
                                    fill="#2E3192"
                                />
                            </svg>
                        </div>
                        <div class="bottom-triangle">
                            <svg
                                width="206"
                                height="206"
                                viewBox="0 0 206 206"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0 0L109.71 106.708L205.934 205.934L205.934 -9.00164e-06L0 0Z"
                                    fill="#E3188E"
                                />
                            </svg>
                        </div>
                        <div class="bottom-circle">
                            <svg
                                width="194"
                                height="189"
                                viewBox="0 0 194 189"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M-0.00019241 152.897C10.0392 163.607 22.0897 172.234 35.4634 178.287C48.8371 184.34 63.272 187.7 77.9441 188.174C92.6161 188.648 107.238 186.228 120.974 181.051C134.711 175.875 147.294 168.043 158.004 158.004C168.714 147.964 177.341 135.914 183.394 122.54C189.447 109.166 192.807 94.7315 193.281 80.0594C193.755 65.3874 191.335 50.7657 186.158 37.029C180.982 23.2924 173.15 10.7098 163.111 -0.000188363L81.5553 76.4483L-0.00019241 152.897Z"
                                    fill="#34B53A"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <layout-footer />
    </div>
</template>

<script>
import Header from "./header";
import Footer from "./footer";
export default {
    name: "layout",
    components: {
        "layout-header": Header,
        "layout-footer": Footer,
    },
};
</script>
<style lang="scss"></style>
