const getters = {
    getDisplay: (state) => {
        return state.display;
    },
    getConfirm: (state) => {
        return state.onConfirm;
    },
    getIsSetDisabled: (state) => {
        return state.disable;
    },
    getTitle: (state) => {
        return state.title;
    },
};

export default getters;
