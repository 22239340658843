
export default {
    components: {},
    data() {
        return {
            sidebarToggled: false,
        };
    },
    methods: {
        toggleSidebar() {
            this.$parent.$parent.$parent.$emit("toggleSidebar");
        },
    },
};
