<template>
    <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="#666666"
        xmlns="http://www.w3.org/2000/svg"
        style="margin-right: 9px"
    >
        <path
            d="M17.061 18H14.5626V8H17.061V18ZM12.0643 18H9.56587V8H12.0643V18ZM13.3134 0.5C11.673 0.5 10.0486 0.823322 8.533 1.45151C7.01741 2.07969 5.64031 3.00043 4.48033 4.16117C2.13764 6.50537 0.821533 9.68479 0.821533 13C0.821533 16.3152 2.13764 19.4946 4.48033 21.8388C5.64031 22.9996 7.01741 23.9203 8.533 24.5485C10.0486 25.1767 11.673 25.5 13.3134 25.5C16.6265 25.5 19.8039 24.183 22.1466 21.8388C24.4893 19.4946 25.8054 16.3152 25.8054 13C25.8054 11.3585 25.4822 9.73303 24.8545 8.21646C24.2267 6.69989 23.3065 5.3219 22.1466 4.16117C20.9866 3.00043 19.6095 2.07969 18.0939 1.45151C16.5783 0.823322 14.9539 0.5 13.3134 0.5Z"
        />
    </svg>
</template>

<script setup></script>
