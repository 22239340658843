const getters = {
    // getCategory: state => {
    //     return state.categoryList;
    // },
    getDepartment: (state) => {
        return state.departmentList;
    },
};

export default getters;
