const mutations = {
    UPDATE_FIRST_TIME_COMPANY_LOGIN(
        state: { first_time_company_login: boolean },
        status: boolean,
    ) {
        state.first_time_company_login = status;
    },

    UPDATE_PROFILE_IMAGE(state: { profileImage: string }, image: string) {
        state.profileImage = image;
    },

    UPDATE_IS_EMPLOYEE_LIST_LOADED(state: { isEmployeeListLoaded }, status) {
        state.isEmployeeListLoaded = status;
    },

    UPDATE_EMPLOYEE_LISTS(state: { employeeLists }, lists) {
        state.employeeLists = lists;
    },

    PUSH_EMPLOYEE(state: { employeeLists }, employeeDetail) {
        state.employeeLists.push([employeeDetail]);
    },

    RESET_TO_INITIAL_STATE(state) {
        state.isEmployeeListLoaded = false;
        state.employeeLists = {};
    },
};

export default mutations;
