<template>
    <div id="sidenav" class="sidenav" v-if="isCompanySettingsCompleted">
        <app-sidenavheader></app-sidenavheader>
        <app-sidemenuwrapper></app-sidemenuwrapper>
    </div>
</template>

<script>
import HeaderWrapper from "./HeaderWrapper.vue";
import MenuWrapper from "./../sidebar/SideMenuWrapper.vue";

export default {
    components: {
        "app-sidenavheader": HeaderWrapper,
        "app-sidemenuwrapper": MenuWrapper,
    },
    data() {
        return {
            active: true,
        };
    },
    methods: {},
    mounted() {
        // this.$on("toggleNav", () => {
        //   this.active = !this.active;
        // });
    },
    computed: {
        isCompanySettingsCompleted() {
            return this.$store.getters["COMPANY/getIsCompanySettingsCompleted"];
        },
    },
};
</script>
