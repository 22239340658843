export default {
    methods: {
        alertSuccess(successMessage) {
            // this.$nextTick(() => {
            //   this.$validator.reset();
            // });
            // eventBus.$emit("flash-message", successMessage, "success");
            this.$eventBus.emit("flash-message", successMessage);
        },

        alertError(errorMessage) {
            this.$eventBus.emit("flash-message", errorMessage, "error");
        },

        alertWarning(warningMessage) {
            this.$eventBus.emit("flash-message", warningMessage, "warning");
        },

        displayValidationError(errors) {
            // let vm = this;
            // let error = errors;
            this.loader_visibility = false;

            // $.each(error, function (field, messages) {
            //     vm.$validator.errors.add({
            //         field: field,
            //         msg: messages[0],
            //     });
            //     // console.log(errors);

            //     // setTimeout(function () {
            //     //     vm.$validator.errors.clear();
            //     // }, 5000);
            // });
        },

        // resetValidationError(field, scope = null){
        //     let vm = this;
        //     if (vm.$validator.errors.has(field, scope)) {
        //         vm.$validator.errors.remove(field, scope);
        //     }
        // }
    },
};
