import axios from "axios";
import store from "./../store";

const Auth =
    process.env.VUE_APP_API_BASE_URL +
    process.env.VUE_APP_IDENTITY_SERVICE_BASE_URL;
// const apiService = new ApiService();

const headers = {
    'Authorization': 'Bearer '
}
export default class AuthenticationService {
    public login(data: any) {
        return axios.post(Auth + "/login", data, {
            headers: headers
        });

    }

    public renewToken() {
        // const userDetail = store.getters['AUTH/getUserDetails'];
        // const email = userDetail.email;
        const email = localStorage.getItem("email");
        const refresh_token = store.getters["AUTH/getRefreshToken"];
        const data = {
            refresh_token: refresh_token,
            email: email,
        };
        return new Promise((resolve, reject) => {
            axios
                .post(Auth + "/renewtoken", data, {
                    headers: headers
                })
                .then((response) => {
                    localStorage.setItem(
                        "access_token",
                        response.data.data.access_token,
                    );
                    store.dispatch(
                        "AUTH/addAccessToken",
                        response.data.data.access_token,
                    );
                    resolve(true);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    public isAuthenticated() {
        return new Promise((resolve, reject) => {
            const isUserAuthenticated = store.state.AUTH.isAuthenticated;
            if (isUserAuthenticated === true) {
                resolve(true);
                // const userId = store.getters['AUTH/getUserId'];
                // const isUserDataLoaded = store.getters['AUTH/getIsUserDataLoaded'];
                // if(isUserDataLoaded === false){
                //     await axios.get(Auth + '/users/' + userId, {
                //         headers: {
                //             'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                //             'X-User-UUID' : userId
                //         }
                //     }).then(response => {
                //         store.dispatch('AUTH/AddUserDetails', response.data.data);
                //         store.dispatch('AUTH/UpdateUserDetailLoaded', true);
                //     }).catch(err => {

                //     })
                // }
                // resolve(true);
            } else {
                resolve(false);
            }
        });
    }

    public logout() {
        return new Promise((resolve, reject) => {
            const feature_banner = localStorage.getItem("feature_banner");
            const birthday_banner = localStorage.getItem("birthday");
            const anniversary_banner = localStorage.getItem("anniversary");
            const email = localStorage.getItem("email");
            const remember_option = localStorage.getItem("remember_option");



            localStorage.clear();
            if (feature_banner != null) {
                localStorage.setItem("feature_banner", feature_banner);
            }
            if (birthday_banner != null) {
                localStorage.setItem("birthday", birthday_banner);
            }
            if (anniversary_banner != null) {
                localStorage.setItem("anniversary", anniversary_banner);
            }
            if (email != null && remember_option) {
                localStorage.setItem("email", email);
            }
            store.dispatch("AUTH/setDefaultAuthState");
            store.dispatch("ATTENDANCE/setDefaultAttendanceState");
            store.dispatch("COMPANY/setDefaultCompanyState");
            store.dispatch("setDefaultState");
            store.dispatch("STORAGE/setDefaultStorageState");
            store.dispatch("NOTICE/setDefaultNoticeState");
            store.dispatch("EMPLOYEE/setDefaultState");
            // store.dispatch('AUTH/UpdateUserAuthenticated', false);
            // store.dispatch('AUTH/addAccessToken', null);
            // store.dispatch('AUTH/UpdateUserDetailLoaded', false);
            // store.dispatch('ATTENDANCE/addCheckInStatus', false);
            // store.dispatch('ATTENDANCE/addCheckOutStatus', false);
            // store.dispatch('ATTENDANCE/addBreakStatus', false);
            // store.dispatch('ATTENDANCE/addAttendanceDetails', {});
            // store.dispatch('COMPANY/UpdateCompanyDetails',{
            //     status: false,
            //     companyDetails: []
            // });
            // store.dispatch('COMPANY/UpdateCompanySettingsCompleted', true);
            // store.dispatch('updateEmployeeListsLoaded', false);
            // store.dispatch('updateEmployeeLists', {});

            // eventBus.emit("displayInitialLoader", false);
            return resolve(true);
        });
    }

    public authenticate(data: { email: string; password: string }) {
        return new Promise((resolve, reject) => {
            axios
                .post(Auth + "/login", data, {
                    headers: headers
                })
                .then((response) => {
                    this.handleAuthentication(response);
                    return resolve(true);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public handleAuthentication(response) {
        localStorage.setItem("access_token", response.data.data.access_token);
        store.dispatch("AUTH/addAccessToken", response.data.data.access_token);

        localStorage.setItem("refresh_token", response.data.data.refresh_token);
        store.dispatch(
            "AUTH/AddRefreshToken",
            response.data.data.refresh_token,
        );

        localStorage.setItem("user_id", response.data.data.user_id);
        store.dispatch("AUTH/AddEmployeeId", response.data.data.user_id);
        store.dispatch("AUTH/UpdateUserAuthenticated", true);
    }

    public forgotPassword(data: any) {
        return axios.post(Auth + "/forgotpassword", data, {
            headers: headers
        });
    }
}
