<template>
    <div class="menu">
        <perfect-scrollbar>
            <superadmin-sidemenu v-if = "isSuperAdmin"></superadmin-sidemenu>
            <side-menu v-else></side-menu>
        </perfect-scrollbar>
        <div class="powered-by px-3">
            <p>&copy; {{ currentYear }} Wiriga</p>
            <p>
                Powered by Sanjeev Thapa UG
                <!-- <span class="text-black">
                    <a class="text-primary d-inline" href="https://www.inctic.io" target="__blank">Sanjeev Thapa UG</a>
                </span> -->
            </p>
        </div>
    </div>
</template>

<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import AlertPopUpMixin from "./../mixins/AlertPopUp";
import moment from "moment";
import SideMenuSuperAdmin from './SideMenuSuperAdmin'
import SideMenu from './SideMenu'

export default {
    mixins: [AlertPopUpMixin],

    data() {
        return {
            settings: {
                maxScrollbarLength: 500,
            },
            currentYear: moment().format("YYYY"),
            isSuperAdmin: this.$store.getters['AUTH/getSuperAdminStatus'],
        };
    },
    components: {
        PerfectScrollbar,
        options: {
            suppressScrollX: true,
        },
        "superadmin-sidemenu": SideMenuSuperAdmin,
        "side-menu": SideMenu,
    },
};
</script>
<style src="vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css" />
<style lang="scss" scoped>
// .ps {
//     height: 300px;
// }
.menu-item {
    &.logout {
        margin-top: auto;
    }
}
</style>
