// import Vue from 'vue';
// import Vuex from 'vuex';
import { createStore } from "vuex";
import actions from "./store/actions";
import mutations from "./store/mutations";
import getters from "./store/getters";
import state from "./store/store";

import AUTH from "./store/auth/index";
import LOADER from "./store/loader/index";
import ATTENDANCE from "./store/attendance/index";
import COMPANY from "./store/company/index";
import STORAGE from "./store/personalStorage/index";
import RECRUITMENT from "./store/recruitment/index";
import DASHBOARD from "./store/dashboard/index";
import NOTICE from "./store/notice/index";
import EMPLOYEE from "./store/employee/index";
import FLASHNOTIFICATION from "./store/flashNotification";
import CONFIRMMODAL from "./store/confirmModal";

// Vue.use(Vuex);

const store = createStore({
    modules: {
        AUTH: AUTH,
        LOADER: LOADER,
        ATTENDANCE: ATTENDANCE,
        COMPANY: COMPANY,
        STORAGE: STORAGE,
        RECRUITMENT: RECRUITMENT,
        DASHBOARD: DASHBOARD,
        NOTICE: NOTICE,
        EMPLOYEE: EMPLOYEE,
        FLASHNOTIFICATION,
        CONFIRMMODAL,
    },
    state,
    getters,
    mutations,
    actions,
});

export default store;
