<template>
    <div class="popup-wrapper">
        <div class="remarks leave-remarks popup">
            <div class="header">
                <div class="title">Enter your remarks:</div>
                <a
                    href="javascript:"
                    class="closepopup"
                    @click="remarkssubmit('cancel')"
                ></a>
            </div>
            <div class="remarks-content">
                <textarea
                    name
                    id
                    v-model="reason"
                    @input="resetCustomValidation"
                ></textarea>
                <span class="error-text" v-if="errors.remarks != ''">{{
                    errors.remarks
                }}</span>
            </div>
            <div class="d-flex">
                <button
                    class="btn btn-primary"
                    @click="remarkssubmit('submit')"
                    :disabled="loader_visibility"
                    v-if="!loader_visibility"
                >
                    Submit
                </button>
                <form-loader v-if="loader_visibility"></form-loader>
            </div>
        </div>
    </div>
</template>

<script>
import FormLoader from "./../loader/Form";

export default {
    components: {
        "form-loader": FormLoader
    },
    data() {
        return {
            reason: "",
            loader_visibility: false,
            errors: { remarks: "" }
        };
    },
    mounted() {
        document.getElementsByTagName("body")[0].classList.add("hide-overflow");
    },
    props: ["leavedata"],
    methods: {
        remarkssubmit(value) {
            this.loader_visibility = true;
            if (value === "cancel") {
                this.loader_visibility = true;

                this.$emit("submitRemarks", {
                    value: value,
                    data: this.leavedata
                });
            }           
            if (value === "submit") {
                this.leavedata.admin_response = this.reason;
                this.$emit("submitRemarks", {
                    value: value,
                    data: this.leavedata
                });
            }

            document
                .getElementsByTagName("body")[0]
                .classList.remove("hide-overflow");
        },
        resetCustomValidation() {
            this.errors.remarks = "";
        }
    },

    beforeDestroy() {
        document
            .getElementsByTagName("body")[0]
            .classList.remove("hide-overflow");
    }
};
</script>

<style lang="scss" scoped></style>
