<template>
    <div class="time-logs d-flex">
        <ul class="time-format d-flex list-inline mb-0">
            <li class="hour">{{ props.hour }}</li>
            <li>:</li>
            <li class="minute">{{ props.minute }}</li>
            <li>:</li>
            <li class="seconds">{{ props.second }}</li>
        </ul>
    </div>
</template>

<script setup>
import { useStore } from "vuex";
import { reactive, ref } from "vue";

const state = useStore();

const checkInStatus = ref(state.getters["ATTENDANCE/getCheckInStatus"]);
// const time = reactive({
//   hour: "00",
//   minute: "00",
//   second: "00",
// });

const props = defineProps({
    hour: Number | String,
    minute: Number | String,
    second: Number | String,
});
</script>
