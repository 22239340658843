const getters = {
    getAccessToken: (state) => {
        return state.accessToken;
    },

    getRefreshToken: (state) => {
        return state.refreshToken;
    },

    getUserId: (state) => {
        return state.employeeId;
    },

    getCompanyId: (state) => {
        return state.user.company_id;
    },

    getUserDetails: (state) => {
        return state.user;
    },

    getIsUserDataLoaded: (state) => {
        return state.isUserDataLoaded;
    },

    getIsUserAuthenticated: (state) => {
        return state.isAuthenticated;
    },

    getIsTokenRefreshing: (state) => {
        return state.isTokenRefreshing;
    },

    getUserCapabilities: (state) => {
        const capabilities = state.user?.role?.capabilities || [];

        const user_capabilities = [];
        capabilities.forEach((element) => {
            user_capabilities.push(element.slug);
        });

        return user_capabilities;
    },
    getSuperAdminStatus: (state) => { 
        return state.isUserSuperAdmin;
    },
};

export default getters;
