const actions = {
    showPopup({ commit }, confirm, title = "Are you sure?") {
        commit("UPDATE_CONFIRM", confirm);
        commit("UPDATE_TITLE", title);
        commit("UPDATE_DISPLAY_STATUS", true);
    },

    hidePopup({ commit }) {
        commit("UPDATE_CONFIRM", null);
        commit("UPDATE_DISPLAY_STATUS", false);
        commit("UPDATE_IS_DISABLED", false);
    },

    updateDisable({ commit }, state) {
        commit("UPDATE_IS_DISABLED", state);
    },
};

export default actions;
