const actions = {
    UpdateDisplayStatus({ commit }, status) {
        commit("UPDATE_DISPLAY_STATUS", status);
    },

    UpdateDisplayMessage({ commit }, data) {
        commit("UPDATE_DISPLAY", {
            title: data.title,
            message: data.message,
        });
    },

    UpdateDisplayType({ commit }, type) {
        commit("UPDATE_TYPE", type);
    },
};

export default actions;
