<template>
    <div class="check-in-out flex-wrap justify-content-md-between align-items-center">
        <div class="quick-control d-flex align-items-center" v-if="!checkout_status && !loader_visibility">
            <CheckInOut @checkIn="checkin" @checkOut="checkout" @pause="pause" @resume="resume" />
        </div>
        <span class="ellapsed" v-if="checkout_status">Ellapsed time: </span>
        <ElapsedTime :hour="hour" :minute="minute" :second="second" />
    </div>
</template>

<script>
import AlertPopUpMixin from "./../mixins/AlertPopUp";
import ApiService from "./../../services/api";
import moment from "moment";
import FormLoader from "./../../components/shared/loader/Form";
import CheckInOut from "./Attendance/CheckInOut";
import ElapsedTime from "./Attendance/ElapsedTime";
import { closePopup } from "./../../surePopupMethods";
import { showFlashNotification } from "./../../flashNotificationMethods";
const apiservice = new ApiService();
export default {
    mixins: [AlertPopUpMixin],
    components: {
        FormLoader,
        CheckInOut,
        ElapsedTime,
    },
    data() {
        return {
            hour: "00",
            minute: "00",
            second: "00",
            timerHandler: {},
            alert_popup: {
                display: false,
                title: "",
                message: "test",
            },
            popup: false,
            type: "",
            checkin_out_loader: false,
            pause_resume_loader: false,
            loader_visibility: true,
        };
    },
    async created() {
        let vm = this;
        // setTimeout(async () => {
        await apiservice
            .checkStatus()
            .then((response) => {
                this.$store.dispatch(
                    "ATTENDANCE/addAttendanceDetails",
                    response.data.data
                );
                this.$store.dispatch("ATTENDANCE/addAttendanceStatus", {
                    checkIn: response.data.data.check_in,
                    checkOut: response.data.data.check_out,
                    breaks: response.data.data.breaks,
                });
                this.setInitialValues();
                if (
                    this.checkin_status &&
                    !this.break_status &&
                    !this.checkout_status
                ) {
                    this.start();
                }
                this.loader_visibility = false;
            })
            .catch((err) => {
                this.loader_visibility = false;
                // if (err.response.status === 404) {
                //   // console.log("Record doesnot exists");
                // } else {
                //   this.alertError(err.response.data.message);
                // }
            });
        // }, 2000);
    },
    mounted() {
        clearInterval(this.timerHandler);
        window.addEventListener("focus", this.handleFocus);
    },

    beforeDestroy() {
        window.removeEventListener("focus", this.handleFocus);
    },

    computed: {
        checkin_status() {
            return this.$store.getters["ATTENDANCE/getCheckInStatus"];
        },

        checkout_status() {
            return this.$store.getters["ATTENDANCE/getCheckOutStatus"];
        },

        break_status() {
            return this.$store.getters["ATTENDANCE/getBreakStatus"];
        },

        checkin_hour() {
            if (this.checkin_status === true) {
                var checkin =
                    this.$store.getters["ATTENDANCE/getAttendanceDetails"];
                var time = checkin.check_in.split(":");
                return time;
            } else {
                return ["00", "00", "00"];
            }
        },

        checkout_time() {
            if (this.checkout_status === true) {
                var checkout =
                    this.$store.getters["ATTENDANCE/getAttendanceDetails"];
                var time = checkout.check_out.split(":");
                return time;
            } else {
                return ["00", "00", "00"];
            }
        },

        total_time() {
            if (this.checkout_status) {
                var details =
                    this.$store.getters["ATTENDANCE/getAttendanceDetails"];
                var elapsed_time = details.elapsed_time;
                return elapsed_time.split(":");
                // var checkintime = details.check_in;
                // var checkoutime = details.check_out;
                // var time = moment
                //   .utc(
                //     moment(checkoutime, "HH:mm:ss").diff(
                //       moment(checkintime, "HH:mm:ss")
                //     )
                //   )
                //   .format("HH:mm:ss");
                // return time.split(":");
            }
        },
    },

    beforeCreate() {
        this.currentTabHostname = window.location.hostname;
        this.isYourWebsite = () =>
            window.location.hostname === this.currentTabHostname;
    },

    methods: {
        getLocation() {
            return new Promise((resolve, reject) => {
                if (!("geolocation" in navigator)) {
                    reject(new Error("Geolocation is not available."));
                }

                navigator.geolocation.getCurrentPosition(
                    (pos) => {
                        resolve(pos);
                    },
                    (err) => {
                        reject(err);
                    }
                );
            });
        },

        async checkin() {
            let latitude = null,
                longitude = null;
            try {
                const { coords } = await this.getLocation();
                latitude = coords.latitude;
                longitude = coords.longitude;
            } catch (error) {
                console.log(error);
            }

            try {
                const data = {
                    latitude,
                    longitude,
                };
                const response = await apiservice.checkIn({
                    checkin_location: data,
                });
                // closePopup();
                showFlashNotification("Checked in");
                this.$store.dispatch("ATTENDANCE/addCheckInStatus", true);
                this.$store.dispatch(
                    "ATTENDANCE/addAttendanceDetails",
                    response.data.data
                );

                this.start();

                apiservice
                    .filterAttendance({
                        today_date: moment().format("YYYY-MM-DD"),
                    })
                    .then((response) => {
                        this.$store.dispatch(
                            "ATTENDANCE/updateCheckInCount",
                            response.data.data.length
                        );
                    })
                    .catch((err) => {
                        console.log("err: ", err);
                    });
            } catch (error) {
                showFlashNotification("", error.response.data.message, "error");
            } finally {
                closePopup();
            }
        },

        async checkout() {
            let latitude = null,
                longitude = null;
            try {
                const { coords } = await this.getLocation();
                latitude = coords.latitude;
                longitude = coords.longitude;
            } catch (error) {}

            try {
                const checkout_location = {
                    latitude,
                    longitude,
                };
                const response = await apiservice.checkOut({
                    checkout_location,
                });
                showFlashNotification("Checked out");
                this.$store.dispatch("ATTENDANCE/addCheckOutStatus", true);
                this.$store.dispatch(
                    "ATTENDANCE/addAttendanceDetails",
                    response.data.data,
                );
                clearInterval(this.timerHandler);
            } catch (error) {
                showFlashNotification("", error.response.data.message, "error");
            } finally {
                closePopup();
            }
        },
        async pause() {
            try {
                const response = await apiservice.takeBreak();
                this.$store.dispatch("ATTENDANCE/addBreakStatus", true);
                this.stop();
                showFlashNotification("Break taken.");
            } catch (error) {
                showFlashNotification("", error.response.data.message, "error");
            } finally {
                closePopup();
            }
        },

        async resume() {
            try {
                const response = await apiservice.stopBreak();
                this.$store.dispatch("ATTENDANCE/addBreakStatus", false);
                this.start();
                showFlashNotification("Break stopped.");
            } catch (error) {
                showFlashNotification("", error.response.data.message, "error");
            } finally {
                closePopup();
            }
            let vm = this;
        },

        setInitialValues() {
            const attendance =
                this.$store.getters["ATTENDANCE/getAttendanceDetails"];
            let checkin_time = attendance.check_in;
            let elapsed_time = attendance.elapsed_time;
            // let current_time = moment().format('HH:mm:ss');
            // var time = moment.utc(moment(current_time, "HH:mm:ss").diff(moment(checkin_time, "HH:mm:ss"))).format("HH:mm:ss");
            var h = elapsed_time.split(":");
            this.hour = h[0];
            this.minute = h[1];
            this.second = h[2];
        },

        start() {
            if (this.checkin_status) {
                var vm = this;
                this.timerHandler = setInterval(function () {
                    vm.second = parseInt(vm.second) + 1;
                    if (vm.second < 10) vm.second = "0" + vm.second;
                    if (vm.second == 60) {
                        vm.second = "00";
                        vm.minute = parseInt(vm.minute) + 1;
                        if (vm.minute < 10) {
                            vm.minute = "0" + vm.minute;
                        }
                    }
                    if (vm.minute == 60) {
                        vm.minute = "00";
                        vm.hour = parseInt(vm.hour) + 1;
                        if (vm.hour < 10) {
                            vm.hour = "0" + vm.hour;
                        }
                    }
                }, 1000);
            }
        },

        stop() {
            //   clearInterval(this.timerhandler);
            clearInterval(this.timerHandler);
        },
        handleFocus() {
            if (this.checkin_status) {
                if (this.isYourWebsite() && !this.checkout_status) {
                    this.fetchLatestTime();
                    this.start();
                }
            }
        },

        async fetchLatestTime() {
            if (this.checkin_status) {
                clearInterval(this.timerHandler);
                try {
                    const response = await apiservice.checkStatus();
                    this.$store.dispatch(
                        "ATTENDANCE/addAttendanceDetails",
                        response.data.data
                    );
                    let elapsed_time = response.data.data.elapsed_time;
                    var h = elapsed_time.split(":");
                    this.hour = h[0];
                    this.minute = h[1];
                    this.second = h[2];
                } catch (error) {
                    console.error("Error fetching latest time:", error);
                }
            }
        },

        // getCountry() {
        //   return new Promise((resolve, reject) => {
        //     let employee_detail = {};
        //     try {
        //       employee_detail = JSON.parse(localStorage.getItem("user_details"));
        //     } catch (error) {
        //       employee_detail = {};
        //     }

        //     if ("country" in employee_detail && employee_detail.country) {
        //       resolve(employee_detail.country);
        //     } else {
        //       axios
        //         .get("https://extreme-ip-lookup.com/json/")
        //         .then(response => {
        //           let country = response.data.countryCode;
        //           resolve(country);
        //         })
        //         .catch(err => {
        //           reject(err);
        //         });
        //     }
        //   });
        // },
    },
};
</script>

<style lang="scss" scoped>
.loader-fix {
    padding-bottom: 24px;
    display: flex;
    align-items: center;

    .loader-wrapper {
        margin-right: 20px;

        @include media(max-992) {
            margin-right: 5px;
        }

        span {
            @include media(max-992) {
                font-size: 14px;
            }
        }
    }
}
</style>
