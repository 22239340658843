<template>
    <footer>
        <div class="bottom-bar">
            <div class="section-inner">
                <p class="copyright">
                    Designed and build with
                    <svg
                        width="16"
                        height="16"
                        class="heart"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M4 1C6.21 1 8 2.755 8 4.92C8 2.755 9.79 1 12 1C14.21 1 16 2.755 16 4.92C16 8.183 12.766 9.334 8.392 14.528C8.34384 14.585 8.28382 14.6309 8.21611 14.6623C8.14841 14.6938 8.07465 14.7101 8 14.7101C7.92535 14.7101 7.85159 14.6938 7.78389 14.6623C7.71618 14.6309 7.65616 14.585 7.608 14.528C3.234 9.334 0 8.183 0 4.92C0 2.755 1.79 1 4 1Z"
                            fill="#FF0000"
                        />
                    </svg>
                    from Nepal. &copy; {{ currentYear }} Sanjeev Thapa UG.
                </p>
                <ul>
                    <li>
                        <a href="https://www.wiriga.com/privacy" target="_blank">Privacy policy</a>
                    </li>
                    <li>
                        <a href="https://www.wiriga.com/terms-of-service" target="_blank">Terms of service</a>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
</template>
<script>
import moment from "moment";
export default {
    data() {
        return {
            currentYear: moment().format("YYYY"),
        };
    },
};
</script>
