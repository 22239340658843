const actions = {
    addAccessToken({ commit }, accessToken) {
        commit("UPDATE_ACCESS_TOKEN", accessToken);
    },

    AddRefreshToken({ commit }, refreshToken) {
        commit("UPDATE_REFRESH_TOKEN", refreshToken);
    },

    AddEmployeeId({ commit }, employeeId) {
        commit("UPDATE_EMPLOYEE_ID", employeeId);
    },

    AddUserDetails({ commit }, userDetails) {
        commit("UPDATE_USER_DETAILS", userDetails);
    },

    UpdateUserDetailLoaded({ commit }, status) {
        commit("UPDATE_USER_DATA_LOADED", status);
    },

    UpdateUserAuthenticated({ commit }, status) {
        commit("UPDATE_IS_USER_AUTHENTICATED", status);
    },

    UpdateTokenRefreshing({ commit }, status) {
        commit("UPDATE_IS_TOKEN_REFRESHING", status);
    },

    setDefaultAuthState({ commit }) {
        commit("RESET_TO_INITAIL_STATE");
    },
    UpdateUserProfile({ commit }, profileImage) {
        commit("UPDATE_USER_PROFILE_IMAGE", profileImage);
    },
    UpdateUserPasswordUpdateRequiredStatus({ commit }, status) {
        commit("UPDATE_USER_PASSWORD_UPDATE_REQUIRED_STATUS", status);
    },
    updateSuperAdminStatus({ commit }, isUserSuperAdmin) {
        commit("UPDATE_SUPER_ADMIN_STATUS", isUserSuperAdmin);
    },
};

export default actions;
