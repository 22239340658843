<template>
    <div id="dashboard-body" v-if="isCompanySettingsCompleted">
        <SurePopUp v-if="displaySurePopup" />
        <!-- dashboard header -->
        <app-dashboard-header
            v-if="isCompanySettingsCompleted"
        ></app-dashboard-header>
        <div class="dashboard-body-content">
            <!-- <app-dashboardmid v-if="isCompanySettingsCompleted"></app-dashboardmid> -->
            <flash-message></flash-message>
            <!-- <app-loader v-if="this.loaderVisibility === true"></app-loader> -->
            <router-view :key="$route.fullPath"></router-view>
        </div>
    </div>

    <div
        v-if="isCompanySettingsCompleted === false"
        style="min-height: 100vh"
        class="wrapper-has-left-column company-setting-wrapper"
    >
        <!-- <flash-message /> -->
        <SurePopUp v-if="displaySurePopup" />
        <header
            class="d-flex justify-content-between align-items-center"
            id="header"
        >
            <!-- logo -->
            <router-link class="navbar-brand" to="/">
                <svg
                    width="167"
                    height="40"
                    viewBox="0 0 167 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g id="logo">
                        <g id="icon">
                            <path
                                id="bottom-vector"
                                d="M18.2622 18.3513C19.3031 18.3258 20.3386 18.5091 21.3074 18.8905C22.2763 19.2719 23.1589 19.8436 23.903 20.5718C24.7992 21.3734 30.2657 26.5163 31.1569 27.3179C31.6548 27.7859 31.9784 28.0995 31.5552 28.5277C30.4051 29.7126 29.275 27.9551 28.1548 29.1699C27.9748 29.3519 27.7316 29.4576 27.4757 29.465C27.2198 29.4724 26.9709 29.381 26.7807 29.2098C26.5019 28.8911 21.5232 24.3606 21.2195 24.0668C19.3276 22.2446 17.1072 22.2396 15.2053 24.0668C13.6121 25.6052 12.0289 27.1586 10.4806 28.7418C9.90304 29.3342 9.48484 29.4587 8.87246 28.7816C7.87673 27.7012 6.75156 29.6728 5.65625 28.6721C5.15839 28.219 5.22311 27.9054 5.65625 27.4573C7.91158 25.2119 10.137 22.9167 12.3924 20.6963C13.1525 19.9168 14.0676 19.3053 15.0786 18.9014C16.0897 18.4975 17.1742 18.3101 18.2622 18.3513V18.3513Z"
                                fill="#2E3192"
                            />
                            <path
                                id="left-vector"
                                d="M11.6207 13.8954C11.6345 15.824 10.9481 17.692 9.68898 19.1529C8.80278 20.1486 7.81701 21.0796 6.90591 22.0604C6.84112 22.1541 6.75522 22.2313 6.65513 22.2856C6.55505 22.34 6.44358 22.3701 6.32973 22.3735C6.21587 22.3769 6.10281 22.3535 5.99967 22.3052C5.89653 22.2568 5.80618 22.1849 5.73593 22.0953C5.32328 21.6748 4.88605 21.2791 4.42654 20.9104C3.96353 20.4971 4.11787 20.2631 4.58088 19.9146C11.4813 14.6173 7.35399 10.8983 4.30705 7.7916C2.99269 6.43741 1.69824 5.08321 0.354003 3.78378C-0.109012 3.33073 -0.104034 3.08677 0.354003 2.58891C1.31986 1.4936 0.388853 2.48933 1.43935 1.34922C1.82271 0.935993 2.07662 0.955908 2.46495 1.34922C4.75513 3.64936 7.0951 5.90469 9.37035 8.21977C10.8366 9.74279 11.6449 11.7815 11.6207 13.8954V13.8954Z"
                                fill="#E3188E"
                            />
                            <path
                                id="right-vector"
                                d="M24.8988 13.2034C24.8874 11.2848 25.5659 9.42599 26.8106 7.96585C27.6968 6.97012 28.6825 6.04409 29.5936 5.05832C29.6588 4.96494 29.745 4.88818 29.8453 4.83424C29.9457 4.7803 30.0572 4.75069 30.1711 4.74778C30.2849 4.74487 30.3979 4.76876 30.5008 4.81751C30.6037 4.86626 30.6937 4.93852 30.7636 5.02845C31.1784 5.45145 31.6155 5.85197 32.073 6.2283C32.536 6.64153 32.3767 6.87055 31.9187 7.22403C25.0182 12.5213 29.1456 16.2404 32.1925 19.3471C33.5069 20.6863 34.8013 22.0355 36.1455 23.33C36.6086 23.7781 36.6036 24.027 36.1455 24.5199C35.1498 25.6301 36.0908 24.6195 35.0403 25.7596C34.6569 26.1778 34.403 26.1529 34.0147 25.7596C31.7245 23.4594 29.3845 21.2041 27.1093 18.884C25.6484 17.3585 24.8533 15.3151 24.8988 13.2034V13.2034Z"
                                fill="#E3188E"
                            />
                            <path
                                id="circle"
                                d="M18.2423 16.6486C19.9581 16.6486 21.349 15.2577 21.349 13.5419C21.349 11.8262 19.9581 10.4353 18.2423 10.4353C16.5265 10.4353 15.1356 11.8262 15.1356 13.5419C15.1356 15.2577 16.5265 16.6486 18.2423 16.6486Z"
                                fill="#2E3192"
                            />
                            <path
                                id="top-vector"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M13.3333 0C13.3333 1.3026 13.8508 2.55184 14.7719 3.47291C15.6929 4.39399 16.9422 4.91144 18.2448 4.91144C19.5474 4.91144 20.7966 4.39399 21.7177 3.47291C22.6388 2.55184 23.1562 1.3026 23.1562 0H26.6413C26.6413 2.22689 25.7566 4.36257 24.182 5.93722C22.6073 7.51187 20.4717 8.3965 18.2448 8.3965C16.0179 8.3965 13.8822 7.51187 12.3075 5.93722C10.7329 4.36257 9.84827 2.22689 9.84827 0H13.3333Z"
                                fill="#2E3192"
                            />
                        </g>
                        <g id="text">
                            <path
                                d="M81.3767 0.338547L73.2216 29.5334H66.3212L60.8447 8.74251L55.1093 29.5334L48.2537 29.5732L40.3874 0.338547H46.6605L51.8084 23.0064L57.7579 0.338547H64.28L69.876 22.9018L75.0637 0.338547H81.3767Z"
                                fill="#2E3192"
                            />
                            <path
                                d="M85.6932 5.50639C85.4275 5.24238 85.2185 4.92694 85.079 4.57939C84.9395 4.23183 84.8723 3.85946 84.8816 3.48506C84.8816 2.72977 85.1817 2.00543 85.7157 1.47136C86.2498 0.937299 86.9741 0.637265 87.7294 0.637265C88.4847 0.637265 89.2091 0.937299 89.7431 1.47136C90.2772 2.00543 90.5772 2.72977 90.5772 3.48506C90.5867 3.86097 90.5197 4.2349 90.3802 4.5841C90.2407 4.9333 90.0316 5.25047 89.7657 5.51635C89.2173 6.0404 88.488 6.33285 87.7294 6.33285C86.9709 6.33285 86.2416 6.0404 85.6932 5.51635V5.50639ZM90.2735 8.93668V29.3741H85.1057V8.96158L90.2735 8.93668Z"
                                fill="#2E3192"
                            />
                            <path
                                d="M103.133 9.56399C104.255 8.93764 105.523 8.61984 106.808 8.64294V14.0697H105.443C103.815 14.0697 102.596 14.4481 101.769 15.2098C100.943 15.9715 100.535 17.3008 100.535 19.1927V29.3741H95.3667V8.96158H100.535V12.138C101.166 11.0716 102.061 10.1854 103.133 9.56399Z"
                                fill="#2E3192"
                            />
                            <path
                                d="M110.86 5.50639C110.592 5.24365 110.38 4.92878 110.238 4.58117C110.096 4.23355 110.026 3.86053 110.034 3.48506C110.034 2.72977 110.334 2.00543 110.868 1.47136C111.402 0.937299 112.126 0.637265 112.882 0.637265C113.637 0.637265 114.361 0.937299 114.895 1.47136C115.429 2.00543 115.729 2.72977 115.729 3.48506C115.739 3.86097 115.672 4.2349 115.532 4.5841C115.393 4.9333 115.184 5.25047 114.918 5.51635C114.369 6.0404 113.64 6.33285 112.882 6.33285C112.123 6.33285 111.394 6.0404 110.845 5.51635L110.86 5.50639ZM115.441 8.93668V29.3741H110.273V8.96158L115.441 8.93668Z"
                                fill="#2E3192"
                            />
                            <path
                                d="M132.567 9.01136C133.656 9.55593 134.593 10.3615 135.295 11.3563V8.46371H140.498V29.0305C140.529 30.789 140.137 32.5292 139.353 34.1038C138.587 35.5998 137.393 36.8338 135.923 37.6486C134.224 38.5635 132.315 39.0151 130.387 38.958C127.486 38.958 125.106 38.2825 123.247 36.9317C122.348 36.2959 121.6 35.4706 121.054 34.514C120.509 33.5575 120.18 32.4929 120.091 31.3954H125.224C125.495 32.3345 126.117 33.1332 126.961 33.6258C127.95 34.2037 129.082 34.4918 130.227 34.4573C130.895 34.4855 131.561 34.3808 132.187 34.1491C132.814 33.9175 133.388 33.5636 133.877 33.1081C134.813 32.2119 135.281 30.8527 135.281 29.0305V25.8591C134.565 26.8632 133.624 27.6852 132.532 28.2588C131.306 28.9079 129.936 29.2367 128.549 29.2147C126.878 29.2275 125.238 28.7647 123.82 27.8805C122.368 26.9689 121.199 25.6707 120.444 24.1315C119.591 22.4075 119.166 20.5034 119.204 18.5803C119.168 16.6733 119.593 14.7858 120.444 13.0789C121.195 11.5591 122.358 10.2808 123.8 9.38974C125.23 8.52598 126.874 8.07934 128.544 8.10027C129.939 8.07328 131.32 8.38596 132.567 9.01136ZM134.559 15.4338C134.1 14.5731 133.41 13.8576 132.567 13.3677C131.748 12.8911 130.816 12.6419 129.869 12.6458C128.937 12.639 128.021 12.8814 127.215 13.3478C126.376 13.8343 125.687 14.5422 125.224 15.394C124.702 16.3771 124.444 17.478 124.472 18.5903C124.447 19.7124 124.707 20.8224 125.229 21.8165C125.686 22.6924 126.375 23.426 127.22 23.9374C128.015 24.4221 128.928 24.6787 129.859 24.6792C130.806 24.6807 131.737 24.4317 132.557 23.9573C133.4 23.4674 134.09 22.7518 134.549 21.8911C135.065 20.8942 135.318 19.7822 135.286 18.66C135.321 17.5404 135.071 16.4301 134.559 15.4338Z"
                                fill="#2E3192"
                            />
                            <path
                                d="M145.462 13.5867C146.214 12.0666 147.379 10.7883 148.822 9.89756C150.251 9.03297 151.893 8.58624 153.562 8.60809C154.946 8.57843 156.315 8.89504 157.545 9.52914C158.634 10.0837 159.582 10.8788 160.318 11.8542V8.96158H165.526V29.3741H160.318V26.3869C159.607 27.3898 158.664 28.2062 157.57 28.7667C156.324 29.4124 154.936 29.7359 153.532 29.7076C151.868 29.7233 150.233 29.262 148.822 28.3783C147.379 27.4618 146.216 26.1645 145.462 24.6294C144.611 22.9049 144.187 21.0009 144.227 19.0782C144.19 17.175 144.614 15.291 145.462 13.5867ZM159.576 15.9317C159.119 15.0701 158.429 14.3542 157.585 13.8656C156.768 13.3885 155.838 13.1392 154.891 13.1436C153.942 13.1342 153.008 13.3854 152.192 13.87C151.376 14.3546 150.708 15.054 150.261 15.8919C149.735 16.8734 149.475 17.9751 149.505 19.0882C149.477 20.2105 149.738 21.3212 150.261 22.3143C150.717 23.1913 151.406 23.9253 152.253 24.4352C153.047 24.9211 153.96 25.1779 154.891 25.1771C155.837 25.1791 156.767 24.93 157.585 24.4552C158.429 23.9665 159.119 23.2506 159.576 22.389C160.094 21.3925 160.35 20.2805 160.318 19.1579C160.349 18.0369 160.094 16.9266 159.576 15.9317Z"
                                fill="#2E3192"
                            />
                        </g>
                    </g>
                </svg>
            </router-link>
            <div class="user-info dropdown d-flex align-items-center">
                <a
                    href="javascript:"
                    class="user-info-wrapper"
                    data-toggle="dropdown"
                    role="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <figure class="user-icon">
                        <img
                            src="./../../assets/img/demo-user.png"
                            height="53"
                            width="53"
                            alt
                        />
                    </figure>
                    <div class="greet-text fw-500">
                        Hi,&nbsp;{{ this.userFullName }}
                    </div>
                    <img
                        height="50"
                        width="16"
                        src="@/assets/img/dashboard-img/arrow-down-icon.svg"
                        alt="arrow-down-icon"
                        style="margin-left: 10px"
                    />
                </a>
                <!-- dropdowns -->
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                        <a
                            class="dropdown-item"
                            href="javascript:"
                            @click.prevent="logout"
                        >
                            <span class="lnr lnr-exit"></span> Logout
                        </a>
                    </li>
                </ul>
            </div>
        </header>

        <router-view :key="$route.fullPath"></router-view>
        <!-- <footer-layout /> -->
    </div>
</template>

<script>
import FlashMessage from "./../shared/popup/Alert";
import Loader from "./../shared/loader/FingerPrint";
import dashboardheader from "./Header.vue";
import dashboardmid from "./Mid.vue";
// import FooterLayout from "./../../views/landing/footer";
import AuthenticationService from "./../../services/auth";
import SurePopUp from "./../shared/popup/Sure";
import PopUp from "./../shared/popup/PopUp";

const authenticationservice = new AuthenticationService();
export default {
    components: {
        "app-dashboard-header": dashboardheader,
        "app-dashboardmid": dashboardmid,
        "app-loader": Loader,
        "flash-message": FlashMessage,
        // "footer-layout": FooterLayout,
        SurePopUp,
        PopUp,
    },

    data() {
        return {
            firstTime: false,
        };
    },

    computed: {
        loaderVisibility() {
            // return this.$store.getters["LOADER/getLoaderVisibility"];
            return true;
        },

        isCompanySettingsCompleted() {
            return this.$store.getters["COMPANY/getIsCompanySettingsCompleted"];
            // return false;
        },

        userFullName() {
            let userDetails = this.$store.getters["AUTH/getUserDetails"];
            return userDetails.first_name + " " + userDetails.last_name;
        },

        displaySurePopup() {
            return this.$store.getters["CONFIRMMODAL/getDisplay"];
        },
    },
    methods: {
        logout() {
            authenticationservice
                .logout()
                .then((response) => {
                    this.$router.push({
                        path: "/login",
                    });
                })
                .catch((err) => {
                    this.alertError("Could not logout.");
                });
        },
    },
};
</script>

<style lang="scss">
// mini mode
.mini {
    .sidebar {
        @media screen and (max-width: 1160px) {
            padding-top: 30px;
            display: flex;
            width: 100%;
            .internal-title svg {
                height: 36px;
            }
            justify-content: space-between;
        }
        @media screen and (max-width: 767px) {
            flex-direction: column;
        }
    }
}

// flash message customization
</style>
