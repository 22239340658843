const state = {
    isBirthdayDetailLoaded: false,
    birthdayUserDetail: [],
    isAnniversaryDetailLoaded: false,
    anniversaryUserDetail: [],
    isUpcomingBirthdayLoaded: false,
    upcomingBirthdayUserDetail: [],
};

export default state;
