import store from "./store";

export const showPopup = (confirmMethod) => {
    store.dispatch("CONFIRMMODAL/showPopup", confirmMethod);
};

export const closePopup = () => {
    document.getElementsByTagName("body")[0].classList.remove("hide-overflow");
    store.dispatch("CONFIRMMODAL/hidePopup");
};

export const disableYesButton = () => {
    store.dispatch("CONFIRMMODAL/updateDisable", true);
};
