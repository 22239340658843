const state = {
    accessToken: localStorage.getItem("access_token"),
    refreshToken: localStorage.getItem("refresh_token"),
    employeeId: localStorage.getItem("user_id"),
    isUserSuperAdmin: localStorage.getItem("super_admin"),
    user: {},
    isUserDataLoaded: false,
    isAuthenticated: !!localStorage.getItem("access_token"),
    isTokenRefreshing: false,
};

export default state;
