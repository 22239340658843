const actions = {
    UpdateBirthdayUserDetails({ commit }, user_detail) {
        commit("UPDATE_BIRTHDAY_USER_DETAILS", user_detail);
    },
    UpdateBirthdayUserDetailsLoaded({ commit }, status) {
        commit("UPDATE_BIRTHDAY_USER_DETAILS_LOADED", status);
    },
    UpdateAnniversaryUserDetails({ commit }, user_detail) {
        commit("UPDATE_ANNIVERSARY_USER_DETAILS", user_detail);
    },
    UpdateAnniversaryUserDetailsLoaded({ commit }, status) {
        commit("UPDATE_ANNIVERSARY_USER_DETAILS_LOADED", status);
    },
    AddNewBirthdayUser({ commit }, user_detail) {
        commit("ADD_NEW_BIRTHDAY_USER", user_detail);
    },
    RemoveBirthdayUser({ commit }, user_detail) {
        commit("REMOVE_NEW_BIRTHDAY_USER", user_detail);
    },
    AddNewAnniversaryUser({ commit }, user_detail) {
        commit("ADD_NEW_ANNIVERSARY_USER", user_detail);
    },
    RemoveAnniversaryUser({ commit }, user_detail) {
        commit("REMOVE_NEW_ANNIVERSARY_USER", user_detail);
    },
    UpdateUpcomingBirthdayUserDetailsLoaded({ commit }, status) {
        commit("UPDATE_UPCOMING_BIRTHDAY_USER_DETAILS_LOADED", status);
    },
    UpdateUpcomingBirthdayUserDetails({ commit }, user_detail) {
        commit("UPDATE_UPCOMING_BIRTHDAY_USER_DETAILS", user_detail);
    },
};

export default actions;
