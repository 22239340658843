const mutations = {
    UPDATE_DISPLAY_STATUS(state, status) {
        state.display = status;
    },

    UPDATE_CONFIRM(state, confirm) {
        state.onConfirm = confirm;
    },

    UPDATE_TITLE(state, title) {
        state.title = title;
    },

    UPDATE_IS_DISABLED(state, status) {
        state.disable = status;
    },
};

export default mutations;
