<template>
    <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.8807 10.4462L20.1707 2.15623C20.3345 1.96493 20.4201 1.71885 20.4104 1.46718C20.4007 1.2155 20.2963 0.976762 20.1182 0.798667C19.9401 0.620573 19.7014 0.51624 19.4497 0.506519C19.1981 0.496798 18.952 0.582404 18.7607 0.746231L10.4707 9.03623L2.18068 0.73623C1.99237 0.547927 1.73698 0.442139 1.47068 0.442139C1.20437 0.442139 0.94898 0.547927 0.760676 0.73623C0.572373 0.924534 0.466585 1.17993 0.466585 1.44623C0.466585 1.71253 0.572373 1.96793 0.760676 2.15623L9.06068 10.4462L0.760676 18.7362C0.655995 18.8259 0.570974 18.9362 0.510951 19.0603C0.450928 19.1843 0.417197 19.3195 0.411878 19.4572C0.406559 19.5949 0.429764 19.7322 0.480039 19.8606C0.530313 19.9889 0.606571 20.1054 0.704026 20.2029C0.801481 20.3003 0.918028 20.3766 1.04635 20.4269C1.17468 20.4771 1.31201 20.5003 1.44973 20.495C1.58745 20.4897 1.72258 20.456 1.84665 20.396C1.97071 20.3359 2.08103 20.2509 2.17068 20.1462L10.4707 11.8562L18.7607 20.1462C18.952 20.3101 19.1981 20.3957 19.4497 20.3859C19.7014 20.3762 19.9401 20.2719 20.1182 20.0938C20.2963 19.9157 20.4007 19.677 20.4104 19.4253C20.4201 19.1736 20.3345 18.9275 20.1707 18.7362L11.8807 10.4462Z"
            fill="#333333"
        />
    </svg>
</template>

<script setup></script>
