<template>
    <svg
        width="20"
        height="24"
        viewBox="0 0 20 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.7778 13.762V9.77756C17.7778 6.20312 15.35 3.192 12.0611 2.28645C11.7356 1.46645 10.94 0.888672 10 0.888672C9.06 0.888672 8.26445 1.46645 7.93889 2.28645C4.65 3.19312 2.22222 6.20312 2.22222 9.77756V13.762L0.325557 15.6587C0.222176 15.7617 0.14019 15.8841 0.0843171 16.0189C0.0284446 16.1537 -0.000210499 16.2983 1.16408e-06 16.4442V18.6664C1.16408e-06 18.9611 0.117064 19.2437 0.325438 19.4521C0.533812 19.6605 0.816427 19.7776 1.11111 19.7776H18.8889C19.1836 19.7776 19.4662 19.6605 19.6746 19.4521C19.8829 19.2437 20 18.9611 20 18.6664V16.4442C20.0002 16.2983 19.9716 16.1537 19.9157 16.0189C19.8598 15.8841 19.7778 15.7617 19.6744 15.6587L17.7778 13.762ZM17.7778 17.5553H2.22222V16.9042L4.11889 15.0076C4.22227 14.9046 4.30426 14.7821 4.36013 14.6473C4.416 14.5125 4.44466 14.3679 4.44445 14.222V9.77756C4.44445 6.71423 6.93667 4.222 10 4.222C13.0633 4.222 15.5556 6.71423 15.5556 9.77756V14.222C15.5556 14.5176 15.6722 14.7998 15.8811 15.0076L17.7778 16.9042V17.5553ZM10 23.1109C10.6881 23.1118 11.3594 22.8982 11.9205 22.5C12.4817 22.1017 12.9048 21.5385 13.1311 20.8887H6.86889C7.09518 21.5385 7.51831 22.1017 8.07946 22.5C8.6406 22.8982 9.31189 23.1118 10 23.1109Z"
            fill="#2E3192"
        />
    </svg>
</template>

<script setup></script>
