import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "./store";
import layout from "./components/dashboard/Main.vue";
import NewLayout from "./views/landing/layout.vue";
import ApiService from "./services/api";
import AuthenticationService from "./services/auth";
import {
    getCompanyDetails,
    getUserData,
    verifyUserCompanyId,
    verifyIsUserAdmin,
    verifyIsUserSuperAdmin,
    verifyIsCompanySettingsCompleted,
    findGoToStep,
    getCompanyId,
    isPasswordChangeRequired,
} from "./routermethods";

const authenticationservice = new AuthenticationService();
const apiservice = new ApiService();


const routes: Array<RouteRecordRaw> = [
    {
        path: "",
        component: NewLayout,
        meta: { guest: true },
        children: [
            {
                path: "",
                name: "domain",
                component: () => import("./views/landing/domain.vue"),
                meta: { guest: true },
                // beforeEnter:(to,from,next) => {
                //   const sub_domain = window.location.host.split('.')[0];
                //   if(sub_domain !== 'app'){
                //     next({
                //       path: '/login'
                //     })
                //   } else {
                //     next();
                //   }
                // }
            },
            {
                path: "/login",
                name: "login",
                component: () => import("./views/landing/login.vue"),
                meta: { guest: true },
                // beforeEnter: (to, from, next) => {
                //   const isAuthenticated = store.getters["AUTH/getIsUserAuthenticated"];
                //   if (isAuthenticated) {
                //     next({
                //       path: "/dashboard",
                //     });
                //   } else {
                //     next();
                //   }
                // },
            },
            {
                path: "/password",
                component: () => import("./views/landing/resetPassword.vue"),
                meta: { guest: true },
                beforeEnter: (to, from, next) => {
                    const isAuthenticated =
                        store.getters["AUTH/getIsUserAuthenticated"];
                    if (isAuthenticated) {
                        next({
                            path: "/dashboard",
                        });
                    } else {
                        next();
                    }
                },
            },
            // {
            //   path: "/register-company",
            //   // component: () => import("./views/landing/registerCompany.vue"),
            //   component: () => import("./views/landing/register.vue"),
            //   meta: { guest: true },
            //   beforeEnter: (to, from, next) => {
            //     const sub_domain = window.location.host.split('.')[0];
            //     if(sub_domain !== 'app'){
            //       const isAuthenticated = store.getters["AUTH/getIsUserAuthenticated"];
            //       if (isAuthenticated) {
            //         next({
            //           path: "/dashboard",
            //         });
            //       } else {
            //         window.location.href = process.env.VUE_APP_URL_SCHEME +"://app." +process.env.VUE_APP_URL + "/register-company"
            //       }
            //     } else {
            //       next();
            //     }
            //   },
            // },
            {
                path: "/signup/:email?",
                // component: () => import("./views/landing/registerUser.vue"),
                component: () => import("./views/landing/userRegister.vue"),
                meta: { title: "Signup", guest: true },
            },
            {
                path: "/forgot-password",
                name: "forgotpassword",
                component: () => import("./views/landing/forgotPassword.vue"),
                meta: { guest: true },
                beforeEnter: (to, from, next) => {
                    const isAuthenticated =
                        store.getters["AUTH/getIsUserAuthenticated"];
                    if (isAuthenticated) {
                        next({
                            path: "/dashboard",
                        });
                    } else {
                        next();
                    }
                },
            },
            {
                path: "/forgot-slug",
                component: () => import("./views/landing/forgotSlug.vue"),
                meta: { guest: true },
            },
            {
                path: "/change-password",
                name: "changepassword",
                component: () => import("./views/landing/resetPassword.vue"),
                meta: { guest: true },
            },
        ],
    },
    {
        path: "",
        component: layout,
        // meta: { requiresAuth: true },
        children: [
            /******************************    Dashboard ****************************************/
            {
                path: "/dashboard",
                name: "home",
                // component: Dashboard,
                component: () =>
                    import(
                        /* webpackChunkName: "Dashboard" */ "./views/dashboard/DashboardWrapper.vue"
                    ),
                meta: { title: "Dashboard", requiresAuth: true },
                // beforeEnter: (to, from, next) => {
                //   const isFirstTimeLogin = store.getters['COMPANY/getFirstTimeLogin'];
                //   if (isFirstTimeLogin) {
                //     next({
                //       path: '/working-days'
                //     })
                //   } else {
                //     next();
                //   }
                // }
            },

            //*************************************Recruitment************************************* */

            {
                path: "/recruitment/setting",
                name: "recruitment-setting",
                // component: Dashboard,
                component: () =>
                    import("./views/recruitment/RecruitmentSetting.vue"),
                meta: {
                    title: "Recruitment",
                    requiresAuth: true,
                    handleJob: true,
                },
            },
            {
                path: "/applicant/:jobId/",
                name: "applicant",
                // component: Dashboard,
                component: () => import("./views/recruitment/Applicant.vue"),
                meta: {
                    title: "Recruitment",
                    requiresAuth: true,
                    handleJob: true,
                },
            },
            {
                path: "/create/job",
                name: "create-job",
                // component: Dashboard,
                component: () => import("./views/recruitment/CreateJob.vue"),
                meta: {
                    title: "Recruitment",
                    requiresAuth: true,
                    handleJob: true,
                },
            },
            {
                path: "/job/list",
                name: "job-list",
                // component: Dashboard,
                component: () => import("./views/recruitment/PublisedJob.vue"),
                meta: {
                    title: "Recruitment",
                    requiresAuth: true,
                    handleJob: true,
                },
            },
            {
                path: "/job/:jobId",
                name: "job_edit",
                component: () =>
                    import(
                        /* webpackChunkName: "EditJob" */ "./views/recruitment/EditJob.vue"
                    ),
                meta: {
                    title: "Recruitment",
                    requiresAuth: true,
                    handleJob: true,
                },
            },

            // *********************************************************************************************************
            // ************************** Leaves ***********************************************************************
            // *********************************************************************************************************

            /******************************  Lists of pending leaves  ****************************************/
            {
                path: "/pending-leaves",
                name: "pending-leaves",
                // component: PendingLeaves,
                component: () =>
                    import(
                        /* webpackChunkName: "PendingLeaves" */ "./views/leave/Pending.vue"
                    ),
                meta: {
                    title: "Awaiting approval",
                    requiresAuth: true,
                    HandleLeave: true,
                },
            },

            /******************************  Apply Leave  ****************************************/
            {
                path: "/leave",
                name: "apply-leave",
                // component: Applyforleave,
                component: () =>
                    import(
                        /* webpackChunkName: "ApplyLeave" */ "./views/leave/Apply.vue"
                    ),
                meta: { title: "Apply for leave", requiresAuth: true },
            },

            /******************************  Add Employee Leave ****************************************/
            {
                path: "/add-employee-leave",
                name: "Employee-Leave_Add",
                // component: AddEmployeeLeave,
                component: () =>
                    import(
                        /* webpackChunkName: "AddEmployeeLeave" */ "./views/leave/AddEmployeeLeave.vue"
                    ),
                meta: {
                    title: "Add leave manually",
                    requiresAuth: true,
                    HandleLeave: true,
                },
            },

            /******************************  Leave Lists of current user ****************************************/
            {
                path: "/my-leave",
                name: "my-leave-report",
                // component: LeaveList,
                component: () =>
                    import(
                        /* webpackChunkName: "MyLeaveReport" */ "./views/leave/List.vue"
                    ),
                meta: { title: "My leave report", requiresAuth: true },
            },

            /******************************  Leave Detail ****************************************/
            {
                path: "/leave-detail/:leaveid",
                name: "leave-detail",
                component: () =>
                    import(
                        /* webpackChunkName: "LeaveDetail" */ "./views/leave/Detail.vue"
                    ),
                // component: LeaveDetail,
                meta: { title: "Leave request", requiresAuth: true },
            },

            /******************************  Edit Leave ****************************************/
            {
                path: "/edit-leave-detail/:leaveid",
                name: "edit-leave-detail",
                component: () =>
                    import(
                        /* webpackChunkName: "EditLeave" */ "./views/leave/Edit.vue"
                    ),
                // component: EditLeaveDetail,
                meta: { title: "Edit leave", requiresAuth: true },
            },

            /******************************  Add New Leave Type ****************************************/
            {
                path: "/leavetypes",
                name: "new_leave_type_add",
                component: () =>
                    import(
                        /* webpackChunkName: "LeaveType" */ "./views/leave/Types.vue"
                    ),
                //  component: AddNewLeaveType,
                meta: {
                    title: "Manage leave type",
                    requiresAuth: true,
                    CustomizeSettings: true,
                },
            },

            /******************************  Approved Leaves List ****************************************/
            {
                path: "/approved-leaves",
                name: "approved-leaves",
                component: () =>
                    import(
                        /* webpackChunkName: "LeaveType" */ "./views/leave/Approved.vue"
                    ),
                meta: {
                    title: "Approved leaves list",
                    requiresAuth: true,
                    CustomizeSettings: true,
                },
            },

            // *********************************************************************************************************
            // ************************** End Of Leaves ****************************************************************
            // *********************************************************************************************************

            // *********************************************************************************************************
            // ******************************* Employee ****************************************************************
            // *********************************************************************************************************

            /******************************  List All Employees ****************************************/
            {
                path: "/employees",
                name: "employee_lists",
                component: () =>
                    import(
                        /* webpackChunkName: "ListEmployee" */ "./views/employee/List.vue"
                    ),
                // component: ListEmployee,
                meta: { title: "Employees", requiresAuth: true },
            },

            /****************************** Add Employee ****************************************/
            {
                path: "/employee",
                name: "add_employee",
                // component: AddEmployee,
                component: () =>
                    import(
                        /* webpackChunkName: "AddEmployee" */ "./views/employee/Add.vue"
                    ),
                meta: {
                    title: "Employees",
                    requiresAuth: true,
                    ActivateDeactivateUsers: true,
                },
            },

            /****************************** Invite Employee ****************************************/
            {
                path: "/invite-employee",
                name: "invite_employee",
                component: () =>
                    import(
                        /* webpackChunkName: "InviteEmployee" */ "./views/employee/Invite.vue"
                    ),
                meta: {
                    title: "Employees",
                    requiresAuth: true,
                    ActivateDeactivateUsers: true,
                },
            },

            /******************************  Import Employee ****************************************/
            {
                path: "/import-employee",
                name: "import_employee",
                component: () =>
                    import(
                        /* webpackChunkName: "ImportEmployee" */ "./views/employee/Import.vue"
                    ),
                meta: {
                    title: "Employees",
                    requiresAuth: true,
                    ActivateDeactivateUsers: true,
                },
            },

            /******************************  List Pending Employees ****************************************/
            {
                path: "/pending-employees",
                name: "pending_employees",
                // component: PendingEmployees,
                component: () =>
                    import(
                        /* webpackChunkName: "PendingEmployees" */ "./views/employee/Pending.vue"
                    ),
                meta: {
                    title: "Employees",
                    requiresAuth: true,
                    HandleUserApprovals: true,
                },
            },

            /******************************  List Terminated Employees ****************************************/
            {
                path: "/terminated-employees",
                name: "terminated_employees",
                // component: TerminatedEmployees,
                component: () =>
                    import(
                        /* webpackChunkName: "TerminatedtEmployees" */ "./views/employee/Terminated.vue"
                    ),
                meta: { title: "Employees", requiresAuth: true, ActivateDeactivateUsers: true },
            },

            // *********************************************************************************************************
            // ******************************* End Of Employee *********************************************************
            // *********************************************************************************************************

            // *********************************************************************************************************
            // ***************************************** Event *********************************************************
            // *********************************************************************************************************

            /******************************  List Company Events ****************************************/
            {
                path: "/events",
                name: "all-event",
                // component: ViewAllEvent,
                component: () =>
                    import(
                        /* webpackChunkName: "Events" */ "./views/event/ViewAll.vue"
                    ),
                meta: {
                    title: "Manage digital notice board",
                    requiresAuth: true,
                },
            },

            /******************************  Add Event ****************************************/
            {
                path: "/event",
                name: "add_event",
                // component: AddNewEvent,
                component: () =>
                    import(
                        /* webpackChunkName: "AddEvent" */ "./views/event/Add.vue"
                    ),
                meta: {
                    title: "Manage digital notice board",
                    requiresAuth: true,
                    HandleEventsAnnouncements: true,
                },
            },

            /******************************  Event Detail ****************************************/
            {
                path: "/event/:eventid",
                name: "event_detail",
                // component: EventDetail,
                component: () =>
                    import(
                        /* webpackChunkName: "EventDetail" */ "./views/event/Detail.vue"
                    ),
                meta: {
                    title: "Manage digital notice board",
                    requiresAuth: true,
                },
            },

            /******************************  Edit Event Detail ****************************************/
            {
                path: "/edit-event-detail/:eventid",
                name: "edit_event_detail",
                // component: EditEventDetail,
                component: () =>
                    import(
                        /* webpackChunkName: "EditEvent" */ "./views/event/Edit.vue"
                    ),
                meta: {
                    title: "Manage digital notice board",
                    requiresAuth: true,
                    HandleEventsAnnouncements: true,
                },
            },

            // *********************************************************************************************************
            // ******************************* End Of Event *********************************************************
            // *********************************************************************************************************

            // *********************************************************************************************************
            // ******************************************** Reporting **************************************************
            // *********************************************************************************************************
            // ************************** End Of  Reporting ******************************************
            // *********************************************************************************************************

            /******************************  Leave Reporting ****************************************/
            {
                path: "/leave-reporting",
                name: "leave_reporting",
                // component: LeaveReport,
                component: () =>
                    import(
                        /* webpackChunkName: "LeaveReporting" */ "./views/leave/Report.vue"
                    ),
                meta: { title: "Leave report", requiresAuth: true },
            },

            /******************************  Attendance Reporting ****************************************/
            {
                path: "/attendance-reporting",
                name: "attendance_report",
                // component: AttendanceReport,
                component: () =>
                    import(
                        /* webpackChunkName: "AttendanceReporting" */ "./views/attendance/Report.vue"
                    ),
                meta: {
                    title: "Attendance report",
                    requiresAuth: true,
                },
            },

            // *********************************************************************************************************
            // ************************** End Of  Reporting ******************************************
            // *********************************************************************************************************

            /******************************  View Employee Details ****************************************/
            {
                path: "/profile/:employee_id?",
                name: "employee_profile",
                // component: Profile,
                component: () =>
                    import(
                        /* webpackChunkName: "Profile" */ "./views/employee/Profile.vue"
                    ),
                meta: { title: "Profile", requiresAuth: true },
            },

            /******************************  Edit Company Roles ****************************************/
            {
                path: "/roles",
                name: "company_role",
                // component: CompanyRoles,
                component: () =>
                    import(
                        /* webpackChunkName: "ManageRoles" */ "./views/company/AddRole.vue"
                    ),
                meta: {
                    title: "Roles",
                    requiresAuth: true,
                    CustomizeSettings: true,
                },
            },

            /******************************  View Invoices List ****************************************/
            {
                path: "/invoices",
                name: "invoices",
                // component: CompanyRoles,
                component: () =>
                    import(
                        /* webpackChunkName: "ManageRoles" */ "./views/company/Invoice.vue"
                    ),
                meta: {
                    title: "Billing",
                    requiresAuth: true,
                    CustomizeSettings: true,
                },
            },

            /******************************  Update Company Detail ****************************************/
            {
                path: "/companies",
                name: "company_profile",
                // component: CompanyRoles,
                component: () =>
                    import(
                        /* webpackChunkName: "ManageRoles" */ "./views/company/Profile.vue"
                    ),
                meta: {
                    title: "Company profile",
                    requiresAuth: true,
                    CustomizeSettings: true,
                },
            },

            {
                path: "/companies-list",
                name: "company_list",
                component: () =>
                    import(
                      "./views/company/CompanyList.vue"
                    ),
                meta: {
                    title: "Companies List",
                    requiresAuth: true,
                },
            },

            /****************************** Working Days **********************************/
            {
                path: "/working-days",
                name: "working_days",
                component: () =>
                    import(
                        /* webpackChunkName: "WorkingDays" */ "./views/company/AddWorkingDays.vue"
                    ),
                meta: {
                    title: "Working days",
                    requiresAuth: true,
                    CustomizeSettings: true,
                },
            },

            /****************************** Manage Working Hours **********************************/
            {
                path: "/working-hours",
                name: "working_hours",
                component: () =>
                    import(
                        /* webpackChunkName: "WorkingHours" */ "./views/company/WorkingHours.vue"
                    ),
                meta: {
                    title: "Working hours",
                    requiresAuth: true,
                    CustomizeSettings: true,
                },
            },

            /*************************** Manage Company Settings *********************/
            {
                path: "/settings",
                name: "manage_settings",
                // component: ManageCompanySettings,
                component: () =>
                    import(
                        /* webpackChunkName: "ManageSettings" */ "./views/company/ManageCompanySettings.vue"
                    ),
                meta: {
                    title: "Manage general settings",
                    requiresAuth: true,
                    CustomizeSettings: true,
                },
            },

            /************************** Manage Company Departments *********************/
            {
                path: "/departments",
                name: "manage_departments",
                component: () =>
                    import("./views/company/ManageDepartments.vue"),
                meta: {
                    title: "Manage departments",
                    requiresAuth: true,
                    CustomizeSettings: true,
                },
            },

            /*************************** View Serach Results *****************************/
            {
                path: "/search-results",
                name: "search_results",
                // component: SearchResults,
                component: () =>
                    import(
                        /* webpackChunkName: "Search" */ "./views/search/Results.vue"
                    ),
                meta: { title: "Search results", requiresAuth: true },
            },

            {
                path: "/personal-storage/:folderlocation?",
                name: "personal_storage",
                component: () =>
                    import(
                        /* webpackChunkName: "PersonalStorage" */ "./views/employee/PersonalStorage.vue"
                    ),
                meta: { title: "My cloud drive", requiresAuth: true, PersonalStorage: true },
            },

            /******************************  Add Announcement ****************************************/
            // {
            //   path: '/add-new-announcement',
            //   name: 'add-announcement',
            //   component: AddAnnouncement,
            //   meta: { title: 'Add Announcement', requiresAuth: true, HandleEventsAnnouncements: true },
            // },

            // /******************************  List Announcement ****************************************/
            // {
            //   path: '/all-announcements',
            //   name: 'all_announcement',
            //   component: ViewAllAnnouncement,
            //   meta: { title: 'All Announcements', requiresAuth: true },
            // },

            // /******************************  Announcement Detail ****************************************/
            // {
            //   path: '/announcement-detail/:announcementid',
            //   name: 'announcement_detail',
            //   component: EventDetail,
            //   meta: { title: 'Announcement Detail', requiresAuth: true },
            // },

            /******************************  Edit Working Days ****************************************/
            // {
            //   path: '/working-days',
            //   name: 'working_days',
            //   component: AddWorkingDays,
            //   meta: { title: 'Manage Working Days', requiresAuth: true, CustomizeSettings: true }
            // },

            // /******************************  Edit Working Days ****************************************/
            // {
            //   path: '/working-hours',
            //   name: 'working_hours',
            //   component: WorkingHours,
            //   meta: { title: 'Manage Working Hours', requiresAuth: true, CustomizeSettings: true }
            // },

            /****************************** Manage Attendance ****************************************/
            {
                path: "/manage-attendance",
                name: "manage_attendance",
                // component: ManageAttendance,
                component: () =>
                    import(
                        /* webpackChunkName: "ManageAttendance" */ "./views/attendance/ManageAttendance.vue"
                    ),
                meta: { title: "Manage attendance", requiresAuth: true },
            },

            {
                path: "/overview",
                name: "overview",
                // component: Overview,
                component: () =>
                    import(
                        /* webpackChunkName: "Overview" */ "./views/overview/Overview.vue"
                    ),
                meta: {
                    title: "Today's overview",
                    // icon: "mid-dashboard-icon.svg",
                    requiresAuth: true,
                    GenerateReports: true,
                },
            },

            {
                path: "/security",
                name: "security",
                component: () =>
                    import(
                        /* webpackChunkName: "Security" */ "./views/employee/UpdatePassword.vue"
                    ),
                meta: { title: "Security", requiresAuth: true },
            },

            {
                path: "/support/:type?",
                name: "support",
                component: () =>
                    import(
                        /* webpackChunkName: "Support" */ "./views/page/Support.vue"
                    ),
                meta: {
                    title: "Need support?",
                    requiresAuth: true,
                    CompanyAdmin: true,
                },
            },

            {
                path: "/manage-integrations",
                name: "integrations",
                component: () =>
                    import(
                        /* webpackChunkName: "Integrations"*/ "./views/company/SlackIntegration.vue"
                    ),
                meta: {
                    title: "Manage integrations",
                    requiresAuth: true,
                    CompanyAdmin: true,
                },
            },
        ],
    },
    {
        path: "/:catchAll(.*)",
        name: "not_found",
        component: () => import("./views/NotFound.vue"),
        meta: { anonymous: true },
    },

    // company registration new design
    {
        path: "/register-company",
        // component: () => import("./views/landing/registerCompany.vue"),
        component: () => import("./views/landing/register.vue"),
        meta: { guest: true },
        beforeEnter: (to, from, next) => {
            const sub_domain = window.location.host.split(".")[0];
            if (sub_domain !== "app") {
                const isAuthenticated =
                    store.getters["AUTH/getIsUserAuthenticated"];
                if (isAuthenticated) {
                    next({
                        path: "/dashboard",
                    });
                } else {
                    window.location.href =
                        process.env.VUE_APP_URL_SCHEME +
                            "://app." +
                            process.env.VUE_APP_ENV ===
                            "local"
                            ? process.env.VUE_APP_URL_LOCAL
                            : process.env.VUE_APP_URL + "/register-company";
                }
            } else {
                next();
            }
        },
    },
];

// let router = new Router({
//   mode: "history",
//   routes,
//   scrollBehavior(to, from, savedPosition) {
//     if (savedPosition) {
//       return savedPosition;
//     }
//     if (to.hash) {
//       return { selector: to.hash };
//     }
//     return { x: 0, y: 0 };
//   },
// });
const router = createRouter({
    history: createWebHistory(),
    routes,
});

let capability_lists = [];
try {
    capability_lists = JSON.parse(localStorage.getItem("user_capabilities"));
} catch (error) {
    capability_lists = [];
}

// router.beforeEach(async (to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (localStorage.getItem("access_token") == null) {
//       next({
//         path: "/login",
//         params: { nextUrl: to.fullPath },
//       });
//     } else {
//       // if (authservice.isAuthenticated()) {
//       authenticationservice.isAuthenticated().then(async (res) => {
//         if (res) {
//           const userId = store.getters['AUTH/getUserId'];
//           const isUserDataLoaded = store.getters['AUTH/getIsUserDataLoaded'];
//           const userDetails = store.getters['AUTH/getUserDetails'];
//           //check if user data is loaded
//           if(isUserDataLoaded === false){
//             // eventBus.$emit('displayInitialLoader', true);
//             let p1 = apiservice.userDetail(localStorage.getItem('user_id'));
//             let p2 = apiservice.employeeDetail(localStorage.getItem('user_id'));
//             await Promise.all([p1, p2]).then(async response => {
//               if(response[0] === undefined || response[1] === undefined){
//                 return;
//               }
//               localStorage.setItem('email', response[0].data.data.email);
//               response[1].data.data['role'] = response[0].data.data.role;
//               let userDetails = { ...response[0].data.data, ...response[1].data.data };
//               store.dispatch('AUTH/AddUserDetails', response[1].data.data);
//               store.dispatch('AUTH/UpdateUserDetailLoaded', true);
//               const verifyCompanyId = await RouterMethods.verifyCompanyId();
//               if(!verifyCompanyId){
//                 authenticationservice.logout().then(response => {
//                   next({
//                     path: "/login"
//                   });
//                 })
//               }
//               if (userDetails.role_id === "d65366a6-e703-4143-b8e2-5b1dd33f773d" ) {
//                 await apiservice.getCompany(userDetails.company_id).then(async response => {
//                   store.dispatch('COMPANY/UpdateCompanyDetails', {
//                     status: true,
//                     companyDetails: response.data.data
//                   });
//                   const stepsCompleted = response.data.data.steps_completed;
//                   store.dispatch('COMPANY/UpdateSettingsStepCompleted', response.data.data.steps_completed);
//                   const isSettingsCompleted = response.data.data.settings_completed;
//                   if (isSettingsCompleted === 0) {
//                     // store.dispatch('COMPANY/UpdateFirstTimeLogin', true);
//                     store.dispatch('COMPANY/UpdateCompanySettingsCompleted', false);
//                     let toPath = to.path;
//                     switch(toPath){
//                       case "/working-days":
//                         next();
//                         break;
//                       case "/working-hours":
//                         if(stepsCompleted >= 1){
//                           next();
//                         } else {
//                           next({
//                             path: '/working-days'
//                           })
//                         }
//                         break;
//                       case "/leavetypes":
//                         if(stepsCompleted >=2) {
//                           next();
//                         } else {
//                           next({
//                             path: '/working-days'
//                           })
//                         }
//                         break;
//                       case "/departments":
//                         if(stepsCompleted >=3) {
//                           next();
//                         } else {
//                           next({
//                             path: '/working-days'
//                           })
//                         }
//                         break;
//                       default:
//                         next({
//                           path: "/working-days"
//                         })
//                     }
//                     // if (to.path === "/working-days" || to.path === "/working-hours" || to.path === "/leavetypes" || to.path === "/departments") {
//                     //   next();
//                     // } else {
//                     //   if (to.path !== "/working-days") {
//                     //     next({
//                     //       path: "/working-days"
//                     //     })
//                     //   }
//                     // }
//                   }
//                 }).catch(err => { });
//               }
//             }).catch(err => {
//               console.log(err);
//             })
//           }
//           // check if user data is loaded and user role is admin and company data is loaded
//           if (isUserDataLoaded === true && userDetails.role_id === "d65366a6-e703-4143-b8e2-5b1dd33f773d") {
//             // eventBus.$emit('displayInitialLoader', true);
//             const verifyCompanyId = await RouterMethods.verifyCompanyId();
//             if(!verifyCompanyId){
//               authenticationservice.logout().then(response => {
//                 next({
//                   path: "/login"
//                 });
//               })
//             }
//             //see if company data is loaded.
//             const isCompanyDataLoaded = store.getters['COMPANY/getCompanyDetailsLoaded'];

//             //if company data is loaded , then check if setting is completed, if setting is not completed, then push into working days
//             if(isCompanyDataLoaded === true){
//               const isSettingsCompleted = store.getters['COMPANY/getIsCompanySettingsCompleted'];
//               const stepsCompleted = store.getters['COMPANY/getSettingsStepCompleted'];
//               if (isSettingsCompleted === false) {
//                 // await store.dispatch('COMPANY/UpdateFirstTimeLogin', true);
//                 await store.dispatch('COMPANY/UpdateCompanySettingsCompleted', false);
//                 let toPath = to.path;
//                 switch (toPath) {
//                   case "/working-days":
//                     next();
//                     break;
//                   case "/working-hours":
//                     if (stepsCompleted >= 1) {
//                       next();
//                     } else {
//                       next({
//                         path: '/working-days'
//                       })
//                     }
//                     break;
//                   case "/leavetypes":
//                     if (stepsCompleted >= 2) {
//                       next();
//                     } else {
//                       next({
//                         path: '/working-days'
//                       })
//                     }
//                     break;
//                   case "/departments":
//                     if (stepsCompleted >= 3) {
//                       next();
//                     } else {
//                       next({
//                         path: '/working-days'
//                       })
//                     }
//                     break;
//                   default:
//                     next({
//                       path: "/working-days"
//                     })
//                 }
//                 // if (to.path === "/working-days" || to.path === "/working-hours" || to.path === "/leavetypes" || to.path === "/departments"){
//                 //   next();
//                 // } else {
//                 //   if(to.path !== "/working-days"){
//                 //     next({
//                 //       path: "/working-days"
//                 //     })
//                 //   }
//                 // }
//               }
//             }

//             //if company data is not loaded, first get company details and then check if settings is completed or not
//             else {
//               // eventBus.$emit('displayInitialLoader', true);
//               await apiservice.getCompany(userDetails.company_id).then(async response => {
//                   store.dispatch('COMPANY/UpdateCompanyDetails',{
//                     status: true,
//                     companyDetails: response.data.data
//                   });
//                 store.dispatch('COMPANY/UpdateSettingsStepCompleted', response.data.data.steps_completed);
//                 const isSettingsCompleted = response.data.data.settings_completed;
//                 const stepsCompleted = store.getters['COMPANY/getSettingsStepCompleted'];
//                 if (isSettingsCompleted === 0) {
//                     // store.dispatch('COMPANY/UpdateFirstTimeLogin', true);
//                     store.dispatch('COMPANY/UpdateCompanySettingsCompleted', false);
//                   let toPath = to.path;
//                   switch (toPath) {
//                     case "/working-days":
//                       next();
//                       break;
//                     case "/working-hours":
//                       if (stepsCompleted >= 1) {
//                         next();
//                       } else {
//                         next({
//                           path: '/working-days'
//                         })
//                       }
//                       break;
//                     case "/leavetypes":
//                       if (stepsCompleted >= 2) {
//                         next();
//                       } else {
//                         next({
//                           path: '/working-days'
//                         })
//                       }
//                       break;
//                     case "/departments":
//                       if (stepsCompleted >= 3) {
//                         next();
//                       } else {
//                         next({
//                           path: '/working-days'
//                         })
//                       }
//                       break;
//                     default:
//                       next({
//                         path: "/working-days"
//                       })
//                   }
//                   // if (to.path === "/working-days" || to.path === "/working-hours" || to.path === "/leavetypes" || to.path === "/departments") {
//                   //   next();
//                   // } else {
//                   //   if (to.path !== "/working-days") {
//                   //     next({
//                   //       path: "/working-days"
//                   //     })
//                   //   }
//                   // }
//                 }
//               }).catch(err => { });
//             }
//           }
//           let capability_lists = [];
//           if(store.getters['AUTH/getIsUserAuthenticated']){
//             capability_lists = store.getters["AUTH/getUserCapabilities"];
//           }
//           // let capability_lists = store.getters["AUTH/getUserCapabilities"];
//           if (
//             to.matched.some((record) => record.meta.HandleEventsAnnouncements)
//           ) {
//             if (capability_lists.includes("handle-events-announcements")) {
//               next();
//             } else {
//               next({
//                 path: "/dashboard",
//               });
//             }
//           } else if (
//             to.matched.some((record) => record.meta.CustomizeSettings)
//           ) {
//             if (capability_lists.includes("customize-settings")) {
//               next();
//             } else {
//               next({
//                 path: "/dashboard",
//               });
//             }
//           } else if (
//             to.matched.some((record) => record.meta.HandleUserApprovals)
//           ) {
//             if (capability_lists.includes("handle-user-approvals")) {
//               next();
//             } else
//               next({
//                 path: "/dashboard",
//               });
//           } else if (to.matched.some((record) => record.meta.GenerateReports)) {
//             let c = capability_lists;
//             if (capability_lists.includes("generate-reports")) {
//               next();
//             } else {
//               next({
//                 path: "/dashboard",
//               });
//             }
//           } else if (to.matched.some((record) => record.meta.Admin)) {
//             if (
//               capability_lists.includes("handle-user-approvals") &&
//               capability_lists.includes("ask-edit-delete-leave")
//             ) {
//               next();
//             } else {
//               next({
//                 path: "/dashboard",
//               });
//             }
//           } else if (
//             to.matched.some((record) => record.meta.ActivateDeactivateUsers)
//           ) {
//             if (capability_lists.includes("activate-deactivate-users")) {
//               next();
//             } else {
//               next({
//                 path: "/dashboard",
//               });
//             }
//           } else if (to.matched.some((record) => record.meta.HandleLeave)) {
//             if (capability_lists.includes("handle-leave")) {
//               next();
//             } else {
//               next({
//                 path: "/dashboard",
//               });
//             }
//           } else if (
//             to.matched.some((record) => record.meta.handleJob)
//           ) {
//             if (capability_lists.includes("handle-jobs")) {
//               next();
//             } else {
//               next({
//                 path: "/dashboard",
//               });
//             }
//           }
//            else if (to.matched.some((record) => record.meta.CompanyAdmin)) {
//             const user = store.getters['AUTH/getUserDetails'];
//             if (user.role_id === "d65366a6-e703-4143-b8e2-5b1dd33f773d"){
//               next()
//             } else {
//               next({
//                 path: "/dashboard",
//               });
//             }
//           }
//           else {
//             next();
//           }
//         } else {
//           next({
//             path: "/login",
//           });
//         }
//       });
//     }
//   } 
// else if (to.matched.some((record) => record.meta.guest)) {
//     const [sub_domain] = window.location.host.split('.');
//     if (sub_domain === 'app' && (to.path === '/login' || to.path=== '/forgot-password')) {
//       return next({ path: '' });
//     }
//     if (sub_domain !== 'app') {
//       try {
//         const response = await apiservice.getCompanyBySlug(sub_domain);
//         const isAuthenticated = await store.getters['AUTH/getIsUserAuthenticated'];
//         store.dispatch('COMPANY/UpdateCompanyDetails', { status: true, companyDetails: response.data.data });
//         store.dispatch('COMPANY/UpdateSettingsStepCompleted', response.data.data.steps_completed);
//         if (isAuthenticated) {
//           return next({ path: '/dashboard' });
//         }
//         if (to.path === '/') {
//           return next({ path: '/login' });
//         }
//         return next();
//       } catch (err) {
//         return next({ name: 'not_found' });
//       }
//     }
//     next();
//   }else if(to.matched.some((record) => record.meta.anonymous)){
//     next()
//   }
// });

router.beforeEach(async (to, from, next) => {
    const [sub_domain] = window.location.host.split(".");

    if (sub_domain !== "app") {
        // await getCompanyDetails();
        await getCompanyId();
    }

    if (
        to.matched.some((record) => {
            return record.meta.requiresAuth;
        })
    ) {
        try {
            const resp = await authenticationservice.isAuthenticated();

            await getUserData();

            await getCompanyDetails();

            await verifyUserCompanyId();

            const isPasswordUpdateRequired = isPasswordChangeRequired();
            if (isPasswordUpdateRequired && to.name !== "security") {
                return next({
                    path: "/security",
                });
                // next({
                //   path: '/security'
                // })
                // return;
                // const { email } = store.getters['AUTH/getUserDetails'];
                // localStorage.clear();
                // store.dispatch('AUTH/UpdateUserAuthenticated', false);
                // return next({
                //  name: 'changepassword',
                //  query: {
                //   email
                //  }
                // })
            }

            const isAdmin = verifyIsUserAdmin();
            const isSuperAdmin = verifyIsUserSuperAdmin();

            if(isSuperAdmin){
                store.dispatch('AUTH/updateSuperAdminStatus', true);
                localStorage.setItem("super_admin","true");
            } else
            {
                store.dispatch('AUTH/updateSuperAdminStatus', false);
                localStorage.setItem("super_admin","false");
            }

            const isCompanySettingsCompleted =
                await verifyIsCompanySettingsCompleted();

            if (isAdmin && !isCompanySettingsCompleted) {
                await findGoToStep(to.path)
                    .then((response) => {
                        return next();
                    })
                    .catch((err) => {
                        return next({
                            path: "/working-days",
                        });
                    });
            }
            if (resp) {
                const userId = store.getters['AUTH/getUserId'];
                const isUserDataLoaded = store.getters['AUTH/getIsUserDataLoaded'];
                const userDetails = store.getters['AUTH/getUserDetails'];
                //check if user data is loaded
                if (isUserDataLoaded === false) {
                    // eventBus.$emit('displayInitialLoader', true);
                    let p1 = apiservice.userDetail(localStorage.getItem('user_id'));
                    let p2 = apiservice.employeeDetail(localStorage.getItem('user_id'));
                    await Promise.all([p1, p2]).then(async response => {
                        if (response[0] === undefined || response[1] === undefined) {
                            return;
                        }
                        localStorage.setItem('email', response[0].data.data.email);
                        response[1].data.data['role'] = response[0].data.data.role;
                        let userDetails = { ...response[0].data.data, ...response[1].data.data };
                        store.dispatch('AUTH/AddUserDetails', response[1].data.data);
                        store.dispatch('AUTH/UpdateUserDetailLoaded', true);
                        const verifyCompanyId = await verifyUserCompanyId();
                        if (!verifyCompanyId) {
                            authenticationservice.logout().then(response => {
                                next({
                                    path: "/login"
                                });
                            })
                        }
                        if (userDetails.role_id === "d65366a6-e703-4143-b8e2-5b1dd33f773d") {
                            await apiservice.getCompany(userDetails.company_id).then(async response => {
                                store.dispatch('COMPANY/UpdateCompanyDetails', {
                                    status: true,
                                    companyDetails: response.data.data
                                });
                                const stepsCompleted = response.data.data.steps_completed;
                                store.dispatch('COMPANY/UpdateSettingsStepCompleted', response.data.data.steps_completed);
                                const isSettingsCompleted = response.data.data.settings_completed;
                                if (isSettingsCompleted === 0) {
                                    // store.dispatch('COMPANY/UpdateFirstTimeLogin', true);
                                    store.dispatch('COMPANY/UpdateCompanySettingsCompleted', false);
                                    let toPath = to.path;
                                    switch (toPath) {
                                        case "/working-days":
                                            next();
                                            break;
                                        case "/working-hours":
                                            if (stepsCompleted >= 1) {
                                                next();
                                            } else {
                                                next({
                                                    path: '/working-days'
                                                })
                                            }
                                            break;
                                        case "/leavetypes":
                                            if (stepsCompleted >= 2) {
                                                next();
                                            } else {
                                                next({
                                                    path: '/working-days'
                                                })
                                            }
                                            break;
                                        case "/departments":
                                            if (stepsCompleted >= 3) {
                                                next();
                                            } else {
                                                next({
                                                    path: '/working-days'
                                                })
                                            }
                                            break;
                                        default:
                                            next({
                                                path: "/working-days"
                                            })
                                    }
                                    // if (to.path === "/working-days" || to.path === "/working-hours" || to.path === "/leavetypes" || to.path === "/departments") {
                                    //   next();
                                    // } else {
                                    //   if (to.path !== "/working-days") {
                                    //     next({
                                    //       path: "/working-days"
                                    //     })
                                    //   }
                                    // }
                                }
                            }).catch(err => { });
                        }
                    }).catch(err => {
                        console.log(err);
                    })
                }
                // check if user data is loaded and user role is admin and company data is loaded
                if (isUserDataLoaded === true && userDetails.role_id === "d65366a6-e703-4143-b8e2-5b1dd33f773d") {
                    // eventBus.$emit('displayInitialLoader', true);
                    const verifyCompanyId = await verifyUserCompanyId();
                    if (!verifyCompanyId) {
                        authenticationservice.logout().then(response => {
                            next({
                                path: "/login"
                            });
                        })
                    }
                    //see if company data is loaded.
                    const isCompanyDataLoaded = store.getters['COMPANY/getCompanyDetailsLoaded'];

                    //if company data is loaded , then check if setting is completed, if setting is not completed, then push into working days
                    if (isCompanyDataLoaded === true) {
                        const isSettingsCompleted = store.getters['COMPANY/getIsCompanySettingsCompleted'];
                        const stepsCompleted = store.getters['COMPANY/getSettingsStepCompleted'];
                        if (isSettingsCompleted === false) {
                            // await store.dispatch('COMPANY/UpdateFirstTimeLogin', true);
                            await store.dispatch('COMPANY/UpdateCompanySettingsCompleted', false);
                            let toPath = to.path;
                            switch (toPath) {
                                case "/working-days":
                                    next();
                                    break;
                                case "/working-hours":
                                    if (stepsCompleted >= 1) {
                                        next();
                                    } else {
                                        next({
                                            path: '/working-days'
                                        })
                                    }
                                    break;
                                case "/leavetypes":
                                    if (stepsCompleted >= 2) {
                                        next();
                                    } else {
                                        next({
                                            path: '/working-days'
                                        })
                                    }
                                    break;
                                case "/departments":
                                    if (stepsCompleted >= 3) {
                                        next();
                                    } else {
                                        next({
                                            path: '/working-days'
                                        })
                                    }
                                    break;
                                default:
                                    next({
                                        path: "/working-days"
                                    })
                            }
                            // if (to.path === "/working-days" || to.path === "/working-hours" || to.path === "/leavetypes" || to.path === "/departments"){
                            //   next();
                            // } else {
                            //   if(to.path !== "/working-days"){
                            //     next({
                            //       path: "/working-days"
                            //     })
                            //   }
                            // }
                        }
                    }

                    //if company data is not loaded, first get company details and then check if settings is completed or not
                    else {
                        // eventBus.$emit('displayInitialLoader', true);
                        await apiservice.getCompany(userDetails.company_id).then(async response => {
                            store.dispatch('COMPANY/UpdateCompanyDetails', {
                                status: true,
                                companyDetails: response.data.data
                            });
                            store.dispatch('COMPANY/UpdateSettingsStepCompleted', response.data.data.steps_completed);
                            const isSettingsCompleted = response.data.data.settings_completed;
                            const stepsCompleted = store.getters['COMPANY/getSettingsStepCompleted'];
                            if (isSettingsCompleted === 0) {
                                // store.dispatch('COMPANY/UpdateFirstTimeLogin', true);
                                store.dispatch('COMPANY/UpdateCompanySettingsCompleted', false);
                                let toPath = to.path;
                                switch (toPath) {
                                    case "/working-days":
                                        next();
                                        break;
                                    case "/working-hours":
                                        if (stepsCompleted >= 1) {
                                            next();
                                        } else {
                                            next({
                                                path: '/working-days'
                                            })
                                        }
                                        break;
                                    case "/leavetypes":
                                        if (stepsCompleted >= 2) {
                                            next();
                                        } else {
                                            next({
                                                path: '/working-days'
                                            })
                                        }
                                        break;
                                    case "/departments":
                                        if (stepsCompleted >= 3) {
                                            next();
                                        } else {
                                            next({
                                                path: '/working-days'
                                            })
                                        }
                                        break;
                                    default:
                                        next({
                                            path: "/working-days"
                                        })
                                }
                                // if (to.path === "/working-days" || to.path === "/working-hours" || to.path === "/leavetypes" || to.path === "/departments") {
                                //   next();
                                // } else {
                                //   if (to.path !== "/working-days") {
                                //     next({
                                //       path: "/working-days"
                                //     })
                                //   }
                                // }
                            }
                        }).catch(err => { });
                    }
                }
                let capability_lists = [];
                if (store.getters['AUTH/getIsUserAuthenticated']) {
                    capability_lists = store.getters["AUTH/getUserCapabilities"];
                }
                // let capability_lists = store.getters["AUTH/getUserCapabilities"];
                if (
                    to.matched.some((record) => record.meta.HandleEventsAnnouncements)
                ) {
                    if (capability_lists.includes("handle-events-announcements")) {
                        next();
                    } else {
                        next({
                            path: "/dashboard",
                        });
                    }
                } else if (
                    to.matched.some((record) => record.meta.CustomizeSettings)
                ) {
                    if (capability_lists.includes("customize-settings")) {
                        next();
                    } else {
                        next({
                            path: "/dashboard",
                        });
                    }
                } else if (
                    to.matched.some((record) => record.meta.HandleUserApprovals)
                ) {
                    if (capability_lists.includes("handle-user-approvals")) {
                        next();
                    } else
                        next({
                            path: "/dashboard",
                        });
                } else if (to.matched.some((record) => record.meta.GenerateReports)) {

                    if (capability_lists.includes("generate-reports")) {
                        next();
                    } else {
                        next({
                            path: "/dashboard",
                        });
                    }
                } else if (to.matched.some((record) => record.meta.Admin)) {
                    if (
                        capability_lists.includes("handle-user-approvals") &&
                        capability_lists.includes("ask-edit-delete-leave")
                    ) {
                        next();
                    } else {
                        next({
                            path: "/dashboard",
                        });
                    }
                } else if (
                    to.matched.some((record) => record.meta.ActivateDeactivateUsers)
                ) {
                    if (capability_lists.includes("activate-deactivate-users")) {
                        next();
                    } else {
                        next({
                            path: "/dashboard",
                        });
                    }
                } else if (to.matched.some((record) => record.meta.HandleLeave)) {
                    if (capability_lists.includes("handle-leave")) {
                        next();
                    } else {
                        next({
                            path: "/dashboard",
                        });
                    }
                } else if (
                    to.matched.some((record) => record.meta.handleJob)
                ) {
                    if (capability_lists.includes("handle-jobs")) {
                        next();
                    } else {
                        next({
                            path: "/dashboard",
                        });
                    }
                }
                else if (to.matched.some((record) => record.meta.CompanyAdmin)) {
                    const user = store.getters['AUTH/getUserDetails'];
                    if (user.role_id === "d65366a6-e703-4143-b8e2-5b1dd33f773d") {
                        next()
                    } else {
                        next({
                            path: "/dashboard",
                        });
                    }
                } else if (to.matched.some((record) => record.meta.PersonalStorage)) {

                    if (capability_lists.includes("upload-files-to-cloud")) {
                        next();
                    } else {
                        next({
                            path: "/dashboard",
                        });
                    }
                }
                else {
                    next();
                }
            } else {
                next({
                    path: "/login",
                });
            }
            // })










        } catch (err) {
            localStorage.clear();
            return next({
                path: "/login",
                params: { nextUrl: to.fullPath },
            });
        }
    }

    if (to.matched.some((record) => record.meta.guest)) {
        if (
            sub_domain === "app" &&
            (to.path === "/login" ||
                to.path === "/forgot-password" ||
                to.path === "/change-password")
        ) {
            return next({
                path: "",
            });
        }

        if (sub_domain !== "app") {
            const isAuthenticated = await store.getters[
                "AUTH/getIsUserAuthenticated"
            ];
            if (isAuthenticated) {
                return next({
                    path: "/dashboard",
                });
            }
            if (to.path === "/" || to.path === "/forgot-slug") {
                return next({ path: "/login" });
            }
        }

        return next();
    }




    if (to.matched.some((record) => record.meta.anonymous)) {
        return next();
    }



});

export default router;
