<template></template>

<script>
import AuthenticationService from "./../../services/auth";
import ApiService from "./../../services/api";

const apiservice = new ApiService();
const authenticationservice = new AuthenticationService();

export default {
    data() {
        return {
            sidenavToggle: false,
            username: "",
            package: "",
            upgrade: true,
            isCompanyAdmin: false,
        };
    },
    created() {
        const detail = this.$store.getters["AUTH/getUserDetails"];
        const company_id = this.$store.getters["AUTH/getCompanyId"];
        this.username = detail.first_name + " " + detail.last_name;
        apiservice
            .getCompany(company_id)
            .then((response) => {
                var company_package = response.data.data.packages[0];
                if (company_package.slug === "start_ups") {
                    this.upgrade = true;
                    this.package = company_package.name + " ( Free )";
                } else {
                    this.upgrade = false;
                    this.package = company_package.name;
                }
            })
            .catch((err) => {
                console.log(err.response.data.message);
            });
    },

    mounted() {
        let vm = this;
        const userDetails = vm.$store.getters["AUTH/getUserDetails"];
        if (userDetails.role_id === "d65366a6-e703-4143-b8e2-5b1dd33f773d") {
            this.isCompanyAdmin = true;
        } else {
            this.isCompanyAdmin = false;
        }
    },

    filters: {
        renamePackage(value) {
            if (value == "Business Yearly" || value == "Business Monthly") {
                return "Business";
            } else {
                return value;
            }
        },
    },

    computed: {
        capability_lists() {
            let capabilities = this.$store.getters["AUTH/getUserCapabilities"];
            return capabilities;
        },

        profile_image() {
            const detail = this.$store.getters["AUTH/getUserDetails"];
            return detail.profile_image;
        },
    },
    methods: {
        toggleSidebar(event) {
            this.$parent.$parent.$emit("toggleNav");
            this.sidenavToggle = !this.sidenavToggle;
        },
        handler(event) {
            let a = event.target.parentElement.parentElement.parentElement;
            document.querySelectorAll(".active")[0].classList.remove("active");
            a.classList.add("active");
            document.querySelectorAll(".active")[1].classList.remove("active");
        },
        logout() {
            authenticationservice
                .logout()
                .then((response) => {
                    this.$router.push({
                        name: "login",
                    });
                })
                .catch((err) => {
                    alert("Could not logout.");
                });
        },
        hideSidenav() {
            document
                .getElementById("main_wrapper")
                .classList.remove("sidebarToggleActive");
            document
                .getElementsByTagName("body")[0]
                .classList.remove("sidebarToggleActive");
        },
    },
};
</script>

<style lang="scss">
#close-sidenav {
    margin-left: 15px;
    position: absolute;
    top: -30px;
    left: 0;
}
</style>
