<template>
    <select ref="selectbox" :disabled="disabled">
        <slot></slot>
    </select>
</template>

<script>
export default {
    props: ["value", "disabled"],
    mounted: function () {
        var vm = this;
        $.fn.selectpicker.Constructor.DEFAULTS.hideDisabled = true;
        $.fn.selectpicker.Constructor.DEFAULTS.noneSelectedText = $(
            this.$refs.selectbox,
        )
            .find("option")
            .first()
            .text();
        // $(this.$el).selectpicker("refresh");
        $(this.$el).selectpicker();
        $(this.$el).on("change", function () {
            vm.$emit("input", $(this.$el).selectpicker("val"));
        });
        // $(this.$el)
        //     .selectpicker("val", this.value)
        //     .on("change", function () {
        //         vm.$emit("input", this.value);
        //     });
    },
    watch: {
        value: function (value) {
            // console.log("inside select picker watch value", value);
            $(this.$el).selectpicker("val", this.value);
        },
    },
    destroyed: function () {
        $(this.$el).off().selectpicker("destroy");
    },
};
</script>
