<template>
    <!-- <app-dashboard></app-dashboard> -->
    <div v-bind:class="{ ie: isIE }">
        <wiriga-loader v-if="displayLoader" />
        <router-view />
    </div>
</template>
<script>
// import dashboard from "./components/dashboard/dashboard.vue";
import "babel-polyfill";
import WirigaLoader from "./components/shared/loader/wiriga-loader.vue";
import { eventBus } from "@/plugins/event-bus.js";

export default {
    name: "App",
    components: {
        // "app-dashboard": dashboard
        "wiriga-loader": WirigaLoader,
    },

    data() {
        return {
            isIE: false,
            displayLoader: false,
        };
    },

    watch: {
        $route: {
            handler: (to, from) => {
                const title = "Wiriga";
                if (to.meta.title) {
                    document.title = title + " | " + to.meta.title;
                } else {
                    document.title = title;
                }
            },
            immediate: true,
        },
    },
    created: function () {
        var ua = window.navigator.userAgent;
        this.isIE = /MSIE|Trident/.test(ua);
    },

    mounted() {
        // eventBus.$on('displayInitialLoader', (status) => {
        //   this.displayLoader = status;
        // })
        // this.$eventBus.on('flash-message', (type, e) => console.log(type, e) )
    },
    created() {},
};
</script>

<style lang="scss">
@import "assets/sass/style.scss";
@import "assets/sass/partials/loginpages.scss";
@import "assets/sass/dashboard.scss";

#main_wrapper {
    // min-height: 100vh;
    // background-color: red;
}
</style>
