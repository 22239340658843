import { createApp } from "vue";
import { createVuetify } from "vuetify";
import Busevent from "./plugins/event-bus";
import router from "./router";
import store from "./store";
import App from "./App.vue";
import VueMask from "@devindex/vue-mask";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import SurePopup from "@/components/shared/popup/Sure.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "./globalComponents";

const vuetify = createVuetify();

export const eventBus = Busevent;

const app = createApp(App);
app.use(router);
app.use(store);
app.use(Busevent);
app.use(vuetify);
app.use(VueMask);
app.component("EasyDataTable", Vue3EasyDataTable);
app.component("surepopup", SurePopup);
app.component("v-select", vSelect);

app.mount("#app");
