const mutations = {
    UPDATE_DISPLAY_STATUS(state: { display }, status) {
        state.display = status;
    },
    UPDATE_DISPLAY(state: { title; message }, data) {
        state.title = data.title;
        state.message = data.message;
    },
    UPDATE_TYPE(state: { type }, type) {
        state.type = type;
    },
};
export default mutations;
