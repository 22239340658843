const actions = {
    UpdateCompanyDetails({ commit }, data) {
        commit("UPDATE_COMPANY_DETAILS", {
            status: data.status,
            company: data.companyDetails,
        });
    },

    UpdateCompanySettingsCompleted({ commit }, status) {
        commit("UPDATE_COMPANY_SETTINGS_COMPLETED", status);
    },

    UpdateSettingsStepCompleted({ commit }, step) {
        commit("UPDATE_COMPANY_SETTINGS_STEP_COMPLETED", step);
    },

    UpdateSubDomainCompanyId({ commit }, companyId) {
        commit("UPDATE_SUB_DOMAIN_COMPANY_ID", companyId);
    },

    AddCompanyDepartment({ commit }, data) {
        commit("ADD_COMPANY_DEPARTMENT", data);
    },

    UpdateCompanyDepartmentById({ commit }, data) {
        commit("UPDATE_COMPANY_DEPARTMENT_BY_ID", {
            department_id: data.department_id,
            department_details: data.details,
        });
    },

    DeleteCompanyDepartmentById({ commit }, departmentId) {
        commit("DELETE_COMPANY_DEPARTMENT_BY_ID", departmentId);
    },

    setDefaultCompanyState({ commit }) {
        commit("RESET_TO_INITIAL_STATE");
    },
};

export default actions;
