<template>
  <ul class="list" ref="mainMenu">
        <li class="menu-item">
            <router-link :to="{ name: 'home' }">
                <div class="icon">
                    <svg width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"
                        fill="#666666">
                        <path
                            d="M7.94118 0H15V5.29412H7.94118V0ZM7.94118 15V6.17647H15V15H7.94118ZM0 15V9.70588H7.05882V15H0ZM0 8.82353V0H7.05882V8.82353H0ZM0.882353 0.882353V7.94118H6.17647V0.882353H0.882353ZM8.82353 0.882353V4.41176H14.1176V0.882353H8.82353ZM8.82353 7.05882V14.1176H14.1176V7.05882H8.82353ZM0.882353 10.5882V14.1176H6.17647V10.5882H0.882353Z" />
                    </svg>
                </div>
                <span>Dashboard</span>
            </router-link>
        </li>
        <li class="menu-item">
                    <router-link :to="{ name: 'company_list' }">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="#666666">
                                <path d="M3 2V13H9V2H3ZM2.5 1H9.5C9.63261 1 9.75979 1.05268 9.85355 1.14645C9.94732 1.24021 10 1.36739 10 1.5V13.5C10 13.6326 9.94732 13.7598 9.85355 13.8536C9.75979 13.9473 9.63261 14 9.5 14H2.5C2.36739 14 2.24021 13.9473 2.14645 13.8536C2.05268 13.7598 2 13.6326 2 13.5V1.5C2 1.36739 2.05268 1.24021 2.14645 1.14645C2.24021 1.05268 2.36739 1 2.5 1Z" fill="#666666"/>
                                <path d="M4 4H8V5H4V4ZM4 7H8V8H4V7ZM4 10H8V11H4V10ZM10 8H12V9H10V8ZM10 10H12V11H10V10ZM1 13H15V14H1V13Z" fill="#666666"/>
                                <path d="M10 6V13H13V6H10ZM9.5 5H13.5C13.6326 5 13.7598 5.05268 13.8536 5.14645C13.9473 5.24021 14 5.36739 14 5.5V13.5C14 13.6326 13.9473 13.7598 13.8536 13.8536C13.7598 13.9473 13.6326 14 13.5 14H9.5C9.36739 14 9.24021 13.9473 9.14645 13.8536C9.05268 13.7598 9 13.6326 9 13.5V5.5C9 5.36739 9.05268 5.24021 9.14645 5.14645C9.24021 5.05268 9.36739 5 9.5 5Z" fill="#666666"/>
                           </svg>
                        </div>
                        <span>Companies</span>
                    </router-link>
                </li>

        <li class="menu-item" ref="logout">
            <a href="javascript:" @click.prevent="logout">
                <div class="icon">
                    <svg width="20" height="19" viewBox="0 0 20 19" fill="#666666"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.09091 18.1818C4.07 18.1818 0 14.1118 0 9.09091C0 4.07 4.07 2.56208e-06 9.09091 2.56208e-06C10.5024 -0.00105786 11.8947 0.327069 13.1572 0.958313C14.4197 1.58956 15.5176 2.50653 16.3636 3.63637H13.9C12.8502 2.71069 11.5556 2.1076 10.1716 1.89946C8.7875 1.69131 7.37279 1.88696 6.09717 2.46292C4.82155 3.03889 3.73923 3.97069 2.98006 5.14654C2.2209 6.32238 1.81715 7.6923 1.81727 9.09192C1.81739 10.4915 2.22136 11.8614 2.98072 13.0371C3.74008 14.2128 4.82256 15.1445 6.09827 15.7202C7.37399 16.2959 8.78874 16.4914 10.1728 16.283C11.5568 16.0746 12.8513 15.4713 13.9009 14.5455H16.3645C15.5184 15.6754 14.4204 16.5925 13.1577 17.2237C11.8951 17.855 10.5026 18.183 9.09091 18.1818ZM15.4545 12.7273V10H8.18182V8.18182H15.4545V5.45455L20 9.09091L15.4545 12.7273Z" />
                    </svg>
                </div>
                <span>Logout</span>
            </a>
        </li>
    </ul>
</template>



<script>
import AuthenticationService from "./../../services/auth";
const authenticationservice = new AuthenticationService();

export default {
    methods: {
        logout() {
            authenticationservice
                .logout()
                .then((response) => {
                    this.$router.push({
                        name: "login",
                    });
                })
                .catch((err) => {
                    this.alertError("Could Not Logout.");
                });
        },
    },
};
</script>
