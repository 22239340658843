const getters = {
    getCheckInStatus: (state) => {
        return state.checkInStatus;
    },

    getCheckOutStatus: (state) => {
        return state.checkOutStatus;
    },

    getBreakStatus: (state) => {
        return state.breakStatus;
    },

    getAttendanceDetails: (state) => {
        return state.attendanceDetails;
    },
    getTotalCheckInCount: (state) => {
        return state.checkInCount;
    },
};

export default getters;
