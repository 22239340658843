<template>
    <div class="loader-wrapper d-flex align-items-center px-4">
        <div class="loader"></div>
        <span>Please wait...</span>
    </div>
</template>
<script>
export default {
    components: {},
};
</script>
<style lang="scss"></style>
