<template>
    <div class="popup-wrapper">
        <app-surepopup
            v-if="type === 'surepopup'"
            :data="data"
            @confirmation="sureconfirmation"
            :title="modalTitle"
        ></app-surepopup>
        <app-remarkspopup
            v-if="type === 'remarkspopup'"
            :leavedata="leavedata"
            @remarksubmit="submitremarks"
        ></app-remarkspopup>
    </div>
</template>

<script>
import SurePopUp from "./Sure.vue";
import RemarksPopUp from "./Remarks.vue";
export default {
    components: {
        "app-surepopup": SurePopUp,
        "app-remarkspopup": RemarksPopUp,
    },
    props: ["type", "data", "leavedata", "modalTitle"],
    data() {
        return {};
    },

    methods: {
        sureconfirmation(value) {
            this.$emit("sureconfirm", value);
        },

        submitremarks(value) {
            this.$emit("remarks", value);
        },
    },
};
</script>
