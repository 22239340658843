const mutations = {
    
    UPDATE_CHECK_IN_COUNT(state: { checkInCount }, count) {
        state.checkInCount = count;
    },
    UPDATE_CHECK_IN_STATUS(state: { checkInStatus }, status) {
        state.checkInStatus = status;
    },

    UPDATE_CHECK_OUT_STATUS(state: { checkOutStatus }, status) {
        state.checkOutStatus = status;
    },

    UPDATE_BREAK_STATUS(state: { breakStatus }, status) {
        state.breakStatus = status;
    },

    UPDATE_ATTENDANCE_DETAILS(state: { attendanceDetails }, attendance) {
        state.attendanceDetails = attendance;
    },

    RESET_TO_INITIAL_STATE(state) {
        state.checkInStatus = false;
        state.checkOutStatus = false;
        state.breakStatus = false;
        state.attendanceDetails = {};
    },
};

export default mutations;
