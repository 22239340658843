import store from "./store";
import ApiService from "./services/api";

const apiservice = new ApiService();

export const getCompanyId = () => {
    return new Promise((resolve, reject) => {
        const companyId = store.getters["COMPANY/getSubDomainComanyId"];
        if (companyId === "") {
            const [sub_domain] = window.location.host.split(".");
            apiservice
                .getCompanyBySlug(sub_domain)
                .then((response) => {
                    const { company_id } = response.data.data;
                    store.dispatch(
                        "COMPANY/UpdateSubDomainCompanyId",
                        company_id,
                    );
                    resolve(true);
                })
                .catch((err) => {
                    reject(err);
                });
        }
        resolve(true);
    });
};

export const getCompanyDetails = () => {
    return new Promise((resolve, reject) => {
        const isCompanyDetailsLoaded =
            store.getters["COMPANY/getCompanyDetailsLoaded"];
        if (!isCompanyDetailsLoaded) {
            const companyId = store.getters["COMPANY/getSubDomainComanyId"];
            apiservice
                .getCompany(companyId)
                .then((response) => {
                    const { settings_completed, steps_completed } =
                        response.data.data;
                    store.dispatch("COMPANY/UpdateCompanyDetails", {
                        status: true,
                        companyDetails: response.data.data,
                    });
                    store.dispatch(
                        "COMPANY/UpdateSettingsStepCompleted",
                        steps_completed,
                    );
                    if (settings_completed === 0) {
                        store.dispatch(
                            "COMPANY/UpdateCompanySettingsCompleted",
                            false,
                        );
                    }
                    resolve(true);
                })
                .catch((err) => {
                    reject(err);
                });
        } else {
            return resolve(true);
        }

        // const [ sub_domain ] = window.location.host.split('.');
        // await apiservice.getCompanyBySlug(sub_domain).then(response => {
        //     store.dispatch('COMPANY/UpdateCompanyDetails', { status: true, companyDetails: response.data.data });
        //     store.dispatch('COMPANY/UpdateSettingsStepCompleted', response.data.data.steps_completed);
        //     resolve(true);
        // }).catch(err => {
        //     reject(err);
        // });
    });
};

export const getUserData = () => {
    return new Promise((resolve, reject) => {
        const userId = store.getters["AUTH/getUserId"];
        const isUserDataLoaded = store.getters["AUTH/getIsUserDataLoaded"];
        if (!isUserDataLoaded) {
            const p1 = apiservice.userDetail(userId);
            const p2 = apiservice.employeeDetail(userId);
            Promise.all([p1, p2])
                .then((response) => {
                    if (
                        response[0] === undefined ||
                        response[1] === undefined
                    ) {
                        return reject(false);
                    }
                    localStorage.setItem("email", response[0].data.data.email);
                    response[1].data.data["role"] = response[0].data.data.role;
                    const userDetails = {
                        ...response[1].data.data,
                        ...response[0].data.data,
                    };
                    store.dispatch("AUTH/AddUserDetails", userDetails);
                    store.dispatch("AUTH/UpdateUserDetailLoaded", true);
                    return resolve(true);
                })
                .catch((err) => {
                    return reject(false);
                });
        } else {
            return resolve(true);
        }
    });
};

export const verifyUserCompanyId = () => {
    return new Promise((resolve, reject) => {
        const { company_id } = store.getters["COMPANY/getCompanyDetails"];
        const { companyId } = store.getters["AUTH/getCompanyId"];
        if (company_id !== companyId) {
            return reject(false);
        }
        return resolve(true);
    });
};

export const verifyIsUserAdmin = () => {
    const { role_id } = store.getters["AUTH/getUserDetails"];
    return role_id === "d65366a6-e703-4143-b8e2-5b1dd33f773d";
};

export const verifyIsUserSuperAdmin = () => {
    const { role_id } = store.getters["AUTH/getUserDetails"];
    return role_id === "e6f94e4d-9f54-4338-aeed-ebb0a887800b";
};

export const verifyIsCompanySettingsCompleted = () => {
    return store.getters["COMPANY/getIsCompanySettingsCompleted"];
};

export const findGoToStep = (toGoPath) => {
    return new Promise((resolve, reject) => {
        const stepsCompleted =
            store.getters["COMPANY/getSettingsStepCompleted"];
        switch (toGoPath) {
            case "/working-days":
                resolve(true);
                break;
            case "/working-hours":
                if (stepsCompleted >= 1) {
                    resolve(true);
                } else {
                    reject(false);
                }
                break;
            case "/leavetypes":
                if (stepsCompleted >= 2) {
                    resolve(true);
                } else {
                    reject(false);
                }
                break;
            case "/departments":
                if (stepsCompleted >= 3) {
                    resolve(true);
                } else {
                    reject(false);
                }
                break;
            default:
                reject(false);
        }
    });
};

export const isPasswordChangeRequired = () => {
    const { password_update_required } = store.getters["AUTH/getUserDetails"];
    return password_update_required;
};
