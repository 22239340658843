<template>
    <div v-show="!isSuperAdmin" class="search-header align-items-center bg-white p-2">
        <img src="@/assets/img/dashboard-img/search-icon.svg" alt="search-icon" /><input v-on:keyup.enter="onEnter"
            type="text" placeholder="Type in search..." class="mb-0" v-model="keyword" />
    </div>
</template>

<script>
import ApiService from "./../../services/api";

const apiservice = new ApiService();
export default {
    data() {
        return {
            keyword: "",
            isSuperAdmin: this.$store.getters['AUTH/getSuperAdminStatus'],
        };
    },

    watch: {
        $route(to, from) {
            if (to.name !== "search_results") {
                this.keyword = "";
            }
        },
    },
    created() {
        if (this.$route?.query?.keyword)
            this.keyword = this.$route.query.keyword;
    },
    methods: {
        onEnter() {
            if (this.keyword !== "") {
                this.$router.push({
                    name: "search_results",
                    query: {
                        keyword: this.keyword,
                    },
                });
            }
            else {
                this.keyword = undefined;
                this.$router.push({
                    name: "search_results",
                    query: {
                        keyword: this.keyword,
                    },
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
input {
    border: none !important;
    width: 300px;
}
</style>
