import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-flex justify-content-center align-items-center container",
  id: "header"
}
const _hoisted_2 = { class: "navbar" }
const _hoisted_3 = {
  class: "navbar-brand",
  href: "https://www.wiriga.com",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logo = _resolveComponent("logo")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("a", _hoisted_3, [
        _createVNode(_component_logo)
      ])
    ])
  ]))
}