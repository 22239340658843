const mutations = {
    UPDATE_COMPANY_DETAILS(
        state: { companyDetails; isCompanyDetailsLoaded },
        data,
    ) {
        state.isCompanyDetailsLoaded = data.status;
        state.companyDetails = data.company;
    },

    UPDATE_COMPANY_SETTINGS_COMPLETED(
        state: { isCompanySettingsCompleted },
        status,
    ) {
        state.isCompanySettingsCompleted = status;
    },

    UPDATE_COMPANY_SETTINGS_STEP_COMPLETED(state: { stepsCompleted }, step) {
        state.stepsCompleted = step;
    },

    UPDATE_SUB_DOMAIN_COMPANY_ID(state: { subDomainCompanyId }, companyId) {
        state.subDomainCompanyId = companyId;
    },

    ADD_COMPANY_DEPARTMENT(state: { companyDetails }, data) {
        state.companyDetails.departments.push(data);
    },

    UPDATE_COMPANY_DEPARTMENT_BY_ID(state: { companyDetails }, data) {
        const index = state.companyDetails.departments.findIndex((x) => {
            return x.id === data.department_id;
        });
        state.companyDetails.departments[index] = data.department_details;
    },

    DELETE_COMPANY_DEPARTMENT_BY_ID(state: { companyDetails }, department_id) {
        const departments = state.companyDetails.departments.filter((x) => {
            return x.id !== department_id;
        });
        state.companyDetails.departments = departments;
    },

    RESET_TO_INITIAL_STATE(state) {
        state.isCompanyDetailsLoaded = false;
        state.companyDetails = [];
        state.isCompanySettingsCompleted = true;
        state.stepsCompleted = 0;
        state.subDomainCompanyId = "";
    },
};

export default mutations;
