const mutations = {
    UPDATE_NOTICE_LIST(state: { isNoticeLoaded; notices }, data) {
        state.isNoticeLoaded = data.status;
        state.notices = data.notices;
    },

    ADD_NOTICE(state: { notices }, notice) {
        state.notices.unshift(notice);
    },

    UPDATE_NOTICE_BY_ID(state: { notices }, data) {
        const index = JSON.parse(JSON.stringify(state.notices)).findIndex(
            (x) => {
                return x.id === data.notice_id;
            },
        );
        state.notices[index] = data.notice;
    },

    DELETE_NOTICE_BY_ID(state: { notices }, noticeId) {
        const notices = state.notices.filter((x) => {
            return x.id !== noticeId;
        });
        state.notices = notices;
    },

    RESET_TO_INITIAL_STATE(state) {
        state.isNoticeLoaded = false;
        state.notices = [];
    },
};

export default mutations;
