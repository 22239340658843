import store from "./store.ts";

export const showFlashNotification = (
    title = "",
    message = "",
    type = "success",
) => {
    store.dispatch("FLASHNOTIFICATION/UpdateDisplayMessage", {
        title,
        message,
    });
    store.dispatch("FLASHNOTIFICATION/UpdateDisplayType", type);
    store.dispatch("FLASHNOTIFICATION/UpdateDisplayStatus", true);
    setTimeout(() => {
        closeFlashNotification();
    }, 3000);
};

export const closeFlashNotification = () => {
    store.dispatch("FLASHNOTIFICATION/UpdateDisplayMessage", {
        title: "",
        message: "",
    });
    store.dispatch("FLASHNOTIFICATION/UpdateDisplayType", "success");
    store.dispatch("FLASHNOTIFICATION/UpdateDisplayStatus", false);
};
