import state from "./store";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

const AUTH = {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};

export default AUTH;
