const mutations = {
    UPDATE_DEPARTMENT_LIST(state, lists) {
        state.departmentList = lists;
    },
    PUSH_DEPARTMENT(state, department) {
        const departments = state.departmentList;
        departments.push(department);
        departments.sort(function (a, b) {
            const nameA = a.department_name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.department_name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            // names must be equal
            return 0;
        });

        state.departmentList = departments;
    },
    UPDATE_DEPARTMENT(state, { departmentId, department }) {
        const departmentData = state.departmentList.findIndex((x) => {
            return x.id === departmentId;
        });
        state.departmentList[departmentData] = department;
    },
    DELETE_DEPARTMENT(state, departmentId) {
        const departments = state.departmentList.filter((element) => {
            return element.id !== departmentId;
        });
        state.departmentList = departments;
    },
};

export default mutations;
