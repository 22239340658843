<template>
    <div class="dashboard-mid-section">
        <!---------- Sure PopUp ---------------->
        <!-- <surepopup :type="'surepopup'" :data="type" v-if="popup" @sureconfirm="confirmevent"></surepopup> -->

        <div class="d-flex flex-wrap justify-content-between">
            <!-- dashboard page title -->
            <h3 class="page-title">
                <img
                    v-if="$route.meta.icon"
                    :src="
                        require(`@/assets/img/dashboard-img/` +
                            $route.meta.icon)
                    "
                />
                {{ $route.meta.title }}
            </h3>

            <!-- Quick control bar -->
            <div
                class="quick-control flex-wrap d-flex"
                v-if="!checkout_status && !loader_visibility"
            >
                <!-- check in/out button -->
                <a
                    href="javascript:"
                    class="btn btn-primary blue-shadow mr-0"
                    @click.prevent="confirm('checkin')"
                    v-if="!checkin_status"
                >
                    <img src="@/assets/img/dashboard-img/clock.svg" alt />
                    CHECK IN
                </a>
                <a
                    href="javascript:"
                    class="btn btn-primary blue-shadow"
                    @click.prevent="confirm('checkout')"
                    v-if="checkin_status"
                >
                    <img src="@/assets/img/dashboard-img/clock.svg" alt />
                    CHECK OUT
                </a>
                <div class="quick-control d-flex" v-if="checkin_out_loader">
                    <div class="loader-fix">
                        <form-loader />
                    </div>
                </div>
                <!-- pause button -->
                <a
                    href="javascript:"
                    class="btn btn-primary inverted"
                    @click.prevent="confirm('pause')"
                    v-if="checkin_status && !break_status"
                >
                    <svg
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="#666666"
                        xmlns="http://www.w3.org/2000/svg"
                        style="margin-right: 9px"
                    >
                        <path
                            d="M17.061 18H14.5626V8H17.061V18ZM12.0643 18H9.56587V8H12.0643V18ZM13.3134 0.5C11.673 0.5 10.0486 0.823322 8.533 1.45151C7.01741 2.07969 5.64031 3.00043 4.48033 4.16117C2.13764 6.50537 0.821533 9.68479 0.821533 13C0.821533 16.3152 2.13764 19.4946 4.48033 21.8388C5.64031 22.9996 7.01741 23.9203 8.533 24.5485C10.0486 25.1767 11.673 25.5 13.3134 25.5C16.6265 25.5 19.8039 24.183 22.1466 21.8388C24.4893 19.4946 25.8054 16.3152 25.8054 13C25.8054 11.3585 25.4822 9.73303 24.8545 8.21646C24.2267 6.69989 23.3065 5.3219 22.1466 4.16117C20.9866 3.00043 19.6095 2.07969 18.0939 1.45151C16.5783 0.823322 14.9539 0.5 13.3134 0.5Z"
                        />
                    </svg>
                    PAUSE
                </a>
                <a
                    href="javacript:"
                    class="btn btn-primary inverted"
                    @click.prevent="confirm('resume')"
                    v-if="checkin_status && break_status"
                >
                    <svg
                        height="20px"
                        style="margin-right: 10px"
                        version="1.1"
                        viewBox="0 0 20 20"
                        width="20px"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                        <title />
                        <desc />
                        <defs />
                        <g
                            fill="none"
                            fill-rule="evenodd"
                            id="Page-1"
                            stroke="none"
                            stroke-width="1"
                        >
                            <g
                                fill="#000000"
                                id="Icons-AV"
                                transform="translate(-42.000000, -85.000000)"
                            >
                                <g
                                    id="pause-circle-outline"
                                    transform="translate(42.000000, 85.000000)"
                                >
                                    <path
                                        d="M7,14 L9,14 L9,6 L7,6 L7,14 L7,14 Z M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 L10,0 Z M10,18 C5.6,18 2,14.4 2,10 C2,5.6 5.6,2 10,2 C14.4,2 18,5.6 18,10 C18,14.4 14.4,18 10,18 L10,18 Z M11,14 L13,14 L13,6 L11,6 L11,14 L11,14 Z"
                                        id="Shape"
                                    />
                                </g>
                            </g>
                        </g>
                    </svg>
                    RESUME
                </a>
            </div>
            <div class="quick-control d-flex" v-if="pause_resume_loader">
                <div class="loader-fix">
                    <form-loader />
                </div>
            </div>

            <!-- check in-out time -->
            <div
                class="time-logs d-flex"
                v-if="checkin_status && !loader_visibility"
            >
                <!-- timer icon -->
                <a
                    v-if="checkin_status"
                    href="javascript:"
                    class="btn btn-primary timer bg-primary"
                >
                    <img
                        src="@/assets/img/dashboard-img/timer-icon.svg"
                        class="mr-0"
                        alt
                    />
                </a>
                <!-- check in time -->
                <div>
                    <span>CHECK IN</span>
                    <ul class="time-format d-flex list-inline mb-0">
                        <li class="hour">{{ checkin_hour[0] }}</li>
                        <li>:</li>
                        <li class="minute">{{ checkin_hour[1] }}</li>
                        <li>:</li>
                        <li class="seconds">{{ checkin_hour[2] }}</li>
                    </ul>
                </div>

                <!-- spacing border -->
                <span class="divider"></span>
                <!-- time elapsed -->
                <div v-if="!checkout_status">
                    <span>TIME ELAPSED</span>
                    <ul class="time-format d-flex list-inline mb-0">
                        <li class="hour">{{ hour }}</li>
                        <li>:</li>
                        <li class="minute">{{ minute }}</li>
                        <li>:</li>
                        <li class="seconds">{{ second }}</li>
                    </ul>
                </div>

                <div v-if="checkout_status">
                    <span>CHECKOUT</span>
                    <ul class="time-format d-flex list-inline mb-0">
                        <li class="hour">{{ checkout_time[0] }}</li>
                        <li>:</li>
                        <li class="minute">{{ checkout_time[1] }}</li>
                        <li>:</li>
                        <li class="seconds">{{ checkout_time[2] }}</li>
                    </ul>
                </div>

                <!-- time elapsed -->
                <span v-if="checkout_status" class="divider"></span>
                <div v-if="checkout_status">
                    <span>TOTAL TIME</span>
                    <ul class="time-format d-flex list-inline mb-0">
                        <li class="hour">{{ total_time[0] }}</li>
                        <li>:</li>
                        <li class="minute">{{ total_time[1] }}</li>
                        <li>:</li>
                        <li class="seconds">{{ total_time[2] }}</li>
                    </ul>
                </div>
            </div>
            <div class="quick-control d-flex" v-if="loader_visibility">
                <div class="loader-fix">
                    <form-loader />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from "./../../main";
import AlertPopUpMixin from "./../mixins/AlertPopUp";
import ApiService from "./../../services/api";
import moment from "moment";
import FormLoader from "./../../components/shared/loader/Form";

const apiservice = new ApiService();
export default {
    mixins: [AlertPopUpMixin],
    components: {
        FormLoader,
    },
    data() {
        return {
            hour: 0,
            minute: 0,
            second: 0,
            timerHandler: {},
            alert_popup: {
                display: false,
                title: "",
                message: "",
            },
            popup: false,
            type: "",
            checkin_out_loader: false,
            pause_resume_loader: false,
            loader_visibility: true,
        };
    },

    async created() {
        let vm = this;
        // setTimeout(async () => {
        await apiservice
            .checkStatus()
            .then((response) => {
                this.$store.dispatch(
                    "ATTENDANCE/addAttendanceDetails",
                    response.data.data,
                );
                this.$store.dispatch("ATTENDANCE/addAttendanceStatus", {
                    checkIn: response.data.data.check_in,
                    checkOut: response.data.data.check_out,
                    breaks: response.data.data.breaks,
                });
                this.setInitialValues();
                if (this.checkin_status && !this.break_status) {
                    this.start();
                }
                this.loader_visibility = false;
            })
            .catch((err) => {
                this.loader_visibility = false;
                if (err.response.status === 404) {
                    // console.log("Record doesnot exists");
                } else {
                    this.alertError(err.response.data.message);
                }
            });
        // }, 2000);
    },

    computed: {
        checkin_status() {
            return this.$store.getters["ATTENDANCE/getCheckInStatus"];
        },

        checkout_status() {
            return this.$store.getters["ATTENDANCE/getCheckOutStatus"];
        },

        break_status() {
            return this.$store.getters["ATTENDANCE/getBreakStatus"];
        },

        checkin_hour() {
            if (this.checkin_status === true) {
                var checkin =
                    this.$store.getters["ATTENDANCE/getAttendanceDetails"];
                var time = checkin.check_in.split(":");
                return time;
            } else {
                return ["00", "00", "00"];
            }
        },

        checkout_time() {
            if (this.checkout_status === true) {
                var checkout =
                    this.$store.getters["ATTENDANCE/getAttendanceDetails"];
                var time = checkout.check_out.split(":");
                return time;
            } else {
                return ["00", "00", "00"];
            }
        },

        total_time() {
            if (this.checkout_status) {
                var details =
                    this.$store.getters["ATTENDANCE/getAttendanceDetails"];
                var elapsed_time = details.elapsed_time;
                return elapsed_time.split(":");
                // var checkintime = details.check_in;
                // var checkoutime = details.check_out;
                // var time = moment
                //   .utc(
                //     moment(checkoutime, "HH:mm:ss").diff(
                //       moment(checkintime, "HH:mm:ss")
                //     )
                //   )
                //   .format("HH:mm:ss");
                // return time.split(":");
            }
        },
    },

    methods: {
        getLocation() {
            return new Promise((resolve, reject) => {
                if (!("geolocation" in navigator)) {
                    reject(new Error("Geolocation is not available."));
                }

                navigator.geolocation.getCurrentPosition(
                    (pos) => {
                        resolve(pos);
                    },
                    (err) => {
                        reject(err);
                    },
                );
            });
        },

        async checkin() {

            let vm = this;
            // this.loader_visibility = true;
            this.checkin_out_loader = true;
            var location = await this.getLocation()
                .then((response) => {
                    return {
                        latitude: response.coords.latitude,
                        longitude: response.coords.longitude,
                    };
                })
                .catch((err) => {
                    return {
                        latitude: null,
                        longitude: null,
                    };
                });
            let checkin_location = {
                latitude: location.latitude,
                longitude: location.longitude,
            };
            let data = {
                checkin_location: checkin_location,
            };

            apiservice
                .checkIn(data)
                .then((response) => {
                    eventBus.$emit("checked-in");
                    this.$store.dispatch("ATTENDANCE/addCheckInStatus", true);
                    this.$store.dispatch(
                        "ATTENDANCE/addAttendanceDetails",
                        response.data.data,
                    );
                    this.start();
                    this.alertSuccess("Checked in.");
                    // this.loader_visibility = false;
                    this.checkin_out_loader = false;


        
                    
                    
                })
                .catch((error) => {
                    // this.loader_visibility = false;
                    this.checkin_out_loader = false;
                    this.alertError(error.response.data.message);
                });
        },

        async checkout() {
            let vm = this;
            // this.loader_visibility = true;
            this.checkin_out_loader = true;
            var location = await this.getLocation()
                .then((response) => {
                    return {
                        latitude: response.coords.latitude,
                        longitude: response.coords.longitude,
                    };
                })
                .catch((err) => {
                    return {
                        latitude: null,
                        longitude: null,
                    };
                });
            let checkout_location = {
                latitude: location.latitude,
                longitude: location.longitude,
            };
            let data = {
                checkout_location: checkout_location,
            };

            apiservice
                .checkOut(data)
                .then((response) => {
                    this.$store.dispatch("ATTENDANCE/addCheckOutStatus", true);
                    this.$store.dispatch(
                        "ATTENDANCE/addAttendanceDetails",
                        response.data.data,
                    );
                    if (this.checkout_status) {
                        clearInterval(this.timerHandler);
                    }

                    this.alertSuccess("Checked out.");
                    // this.loader_visibility = false;
                    this.checkin_out_loader = false;
                })
                .catch((error) => {
                    this.checkin_out_loader = true;
                    // this.loader_visibility = false;
                    this.alertError(error.response.data.message);
                });
        },

        async pause() {
            let vm = this;
            // this.loader_visibility = true;
            this.pause_resume_loader = true;
            apiservice
                .takeBreak()
                .then((response) => {
                    this.$store.dispatch("ATTENDANCE/addBreakStatus", true);
                    if (this.break_status) {
                        clearInterval(this.timerHandler);
                    }

                    this.alertSuccess("Break taken.");
                    // this.loader_visibility = false;
                    this.pause_resume_loader = false;
                })
                .catch((error) => {
                    // this.loader_visibility = false;
                    this.pause_resume_loader = false;
                    this.alertError(error.response.data.message);
                });
        },

        async resume() {
            let vm = this;
            // this.loader_visibility = true;
            this.pause_resume_loader = true;
            apiservice
                .stopBreak()
                .then((response) => {
                    this.$store.dispatch("ATTENDANCE/addBreakStatus", false);
                    this.start();

                    this.alertSuccess("Break stopped.");
                    // this.loader_visibility = false;
                    this.pause_resume_loader = false;
                })
                .catch((error) => {
                    // this.loader_visibility = false;
                    this.pause_resume_loader = false;
                    this.alertError(error.response.data.message);
                });
        },

        setInitialValues() {
            const attendance =
                this.$store.getters["ATTENDANCE/getAttendanceDetails"];
            let checkin_time = attendance.check_in;
            let elapsed_time = attendance.elapsed_time;
            // let current_time = moment().format('HH:mm:ss');
            // var time = moment.utc(moment(current_time, "HH:mm:ss").diff(moment(checkin_time, "HH:mm:ss"))).format("HH:mm:ss");
            var h = elapsed_time.split(":");
            this.hour = h[0];
            this.minute = h[1];
            this.second = h[2];
        },

        start() {
            if (this.checkin_status) {
                var vm = this;
                this.timerHandler = setInterval(function () {
                    vm.second = parseInt(vm.second) + 1;
                    if (vm.second < 10) vm.second = "0" + vm.second;
                    if (vm.second == 60) {
                        vm.second = "00";
                        vm.minute = parseInt(vm.minute) + 1;
                        if (vm.minute < 10) vm.minute = "0" + vm.minute;
                    }
                    if (vm.minute == 60) {
                        vm.minute = 0;
                        vm.hour = parseInt(vm.hour) + 1;
                        if (vm.hour < 10) vm.hour = "0" + vm.hour;
                    }
                }, 1000);
            }
        },

        stop() {
            clearInterval(this.timerhandler);
        },

        // getCountry() {
        //   return new Promise((resolve, reject) => {
        //     let employee_detail = {};
        //     try {
        //       employee_detail = JSON.parse(localStorage.getItem("user_details"));
        //     } catch (error) {
        //       employee_detail = {};
        //     }

        //     if ("country" in employee_detail && employee_detail.country) {
        //       resolve(employee_detail.country);
        //     } else {
        //       axios
        //         .get("https://extreme-ip-lookup.com/json/")
        //         .then(response => {
        //           let country = response.data.countryCode;
        //           resolve(country);
        //         })
        //         .catch(err => {
        //           reject(err);
        //         });
        //     }
        //   });
        // },

        confirm(type) {
            this.type = type;
            this.popup = true;
        },

        confirmevent(value) {
            if (value.value === "cancel") {
                this.type = "";
                this.popup = false;
            }
            if (value.value === "yes") {
                this.type = "";
                this.popup = false;
                switch (value.data) {
                    case "pause":
                        this.pause();
                        break;
                    case "resume":
                        this.resume();
                        break;
                    case "checkin":
                        this.checkin();
                        break;
                    case "checkout":
                        this.checkout();
                        break;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.loader-fix {
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    .loader-wrapper {
        margin-right: 20px;
        @include media(max-992) {
            margin-right: 5px;
        }
        span {
            @include media(max-992) {
                font-size: 14px;
            }
        }
    }
}
</style>
