import state from "./store";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const EMPLOYEE = {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};

export default EMPLOYEE;
