const getters = {
    firsttimelogin: (state) => {
        return state.first_time_company_login;
    },

    profileImage: (state) => {
        return state.profileImage;
    },

    employeeListsLoaded: (state) => {
        return state.isEmployeeListLoaded;
    },

    employeeLists: (state) => {
        return state.employeeLists;
    },
};

export default getters;
