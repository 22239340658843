
import HeaderSearch from "./../header/Search.vue";
import Notification from "./../header/Notification.vue";
import UserInfo from "./../header/UserInfo.vue";
import SideNavToggler from "./../header/SideNavToggler.vue";
import ApiService from "./../../services/api";
import Attendance from "./../header/Attendance.vue";

const apiservice = new ApiService();

export default {
    components: {
        "app-headersearch": HeaderSearch,
        "app-notification": Notification,
        "app-userinfo": UserInfo,
        "app-sidenavtoggler": SideNavToggler,
        Attendance
    },
    data() {
        return {
            isCompanyAdmin: false,
            package: "",
            upgrade: true,
            isSuperAdmin: this.$store.getters['AUTH/getSuperAdminStatus'],
        };
    },

    created() {
        const company_id = this.$store.getters["AUTH/getCompanyId"];
        apiservice
            .getCompany(company_id)
            .then((response) => {
                var company_package = response.data.data.packages[0];
                if (company_package.slug === "start_ups") {
                    this.upgrade = true;
                    this.package = company_package.name + " ( Free )";
                } else {
                    this.upgrade = false;
                    this.package = company_package.name;
                }
            })
            .catch((err) => {
                console.log(err.response.data.message);
            });
    },

    mounted() {
        let vm = this;
        const userDetails = vm.$store.getters["AUTH/getUserDetails"];
        if (userDetails.role_id === "d65366a6-e703-4143-b8e2-5b1dd33f773d") {
            this.isCompanyAdmin = true;
        } else {
            this.isCompanyAdmin = false;
        }
    },

    filters: {
        renamePackage(value) {
            if (value == "Business Yearly" || value == "Business Monthly") {
                return "Business";
            } else {
                return value;
            }
        }
    },
    //hamburger menu
    methods: {
        toggleSidebar(event) {
            document
                .getElementsByTagName("body")[0]
                .classList.toggle("mini-mode");
            this.$parent.$parent.$emit("toggleNav");
        }
    }
};
