<template>
    <a
        href="javascript:"
        class="btn btn-primary me-2"
        v-if="!status.checkedIn"
        @click="checkIn"
    >
        <ClockIcon class="clockIcon" />
        Check in
    </a>
    <a
        href="javascript:"
        class="btn btn-primary me-2"
        v-if="status.checkedIn && !status.checkedOut"
        @click="checkOut"
    >
        <ClockIcon class="clockIcon" />
        Check out
    </a>
    <a
        href="javascript:"
        v-if="status.checkedIn && !status.checkedOut && !status.paused"
        @click="pause"
    >
        <PauseSvg class="pauseIcon" />
    </a>
    <a
        href="javascript:"
        v-if="!status.checkedOut && status.paused"
        @click="resume"
    >
        <ResumeSvg />
    </a>
</template>

<script setup>
import { useStore } from "vuex";
import { reactive, computed } from "vue";
import { showPopup } from "./../../../surePopupMethods";
import PauseSvg from "./../../shared/svg/pause";
import ResumeSvg from "./../../shared/svg/resume";
import ClockIcon from "./../../shared/svg/clockIcon";

const state = useStore();

const emit = defineEmits(["checkIn", "checkOut", "pause", "resume"]);

const status = computed(() => ({
    checkedIn: state.getters["ATTENDANCE/getCheckInStatus"],
    checkedOut: state.getters["ATTENDANCE/getCheckOutStatus"],
    paused: state.getters["ATTENDANCE/getBreakStatus"],
}));

const checkIn = () => {
    showPopup(() => {
        emit("checkIn");
    });
};

const checkOut = () => {
    showPopup(() => {
        emit("checkOut");
    });
};

const pause = () => {
    showPopup(() => {
        emit("pause");
    });
};

const resume = () => {
    showPopup(() => {
        emit("resume");
    });
};
</script>
