const actions = {
    UpdateNoticeList({ commit }, data) {
        commit("UPDATE_NOTICE_LIST", {
            status: data.status,
            notices: data.notices,
        });
    },

    AddNotice({ commit }, notice) {
        commit("ADD_NOTICE", notice);
    },

    UpdateNoticeById({ commit }, data) {
        commit("UPDATE_NOTICE_BY_ID", {
            notice_id: data.id,
            notice: data.notice,
        });
    },

    DeleteNoticeById({ commit }, id) {
        commit("DELETE_NOTICE_BY_ID", id);
    },

    setDefaultNoticeState({ commit }) {
        commit("RESET_TO_INITIAL_STATE");
    },
};
export default actions;
