const actions = {
    updateEmployeeLists({ commit }, data) {
        commit("UPDATE_EMPLOYEE_LISTS", {
            status: data.status,
            employeeLists: data.lists,
        });
    },

    updateEmployee({ commit }, data) {
        commit("UPDATE_EMPLOYEE", {
            employeeId: data.employeeId,
            employeeData: data.employeeData,
        });
    },

    setDefaultState({ commit }) {
        commit("RESET_TO_INITIAL_STATE");
    },
};

export default actions;
