<template>
    <div
        id="main_wrapper"
        v-bind:class="{
            mini: active,
            sidebarToggleActive: sidebarToggled,
            'layout header-with-login': isCompanySettingsCompleted === false
        }"
    >
        <app-sidenav v-if="isUserDataLoaded"></app-sidenav>
        <app-dashboard></app-dashboard>
        <a
            href="#"
            class="mobile-backdrop"
            @click.prevent="untoggleSidebar()"
        ></a>
    </div>
    <a href="#" class="overlay" @click.prevent="untoggleSidebar()"></a>
</template>

<script>
import sidenav from "./../sidenav/Index.vue";
import dashboardbody from "./Body.vue";
import ApiService from "./../../services/api";
// import { eventBus } from "@/main";

const apiservice = new ApiService();

export default {
    components: {
        "app-sidenav": sidenav,
        "app-dashboard": dashboardbody
    },
    data() {
        return {
            active: false,
            sidebarToggled: false,
            userData: [],
            vm: this
            // isUserDataLoaded: this.$store.getters['AUTH/getIsUserDataLoaded']
        };
    },
    async created() {
        // let vm = this;
        //   const isLoggedIn = vm.$store.getters['AUTH/getIsUserAuthenticated'];
        //   const employeeId = this.$store.getters['AUTH/getUserId'];
        //   // const isUserDataLoaded = this.$store.getters['AUTH/getIsUserDataLoaded'];
        //   if(isLoggedIn === true && employeeId !== null){
        //     const userDetails = this.$store.getters['AUTH/getUserDetails'];
        //     if(Object.keys(userDetails).length === 0) {
        //         await apiservice.userDetail(employeeId).then(response => {
        //           this.$store.dispatch('AUTH/AddUserDetails', response.data.data);
        //           this.$store.dispatch('AUTH/UpdateUserDetailLoaded', true);
        //         }).catch(err => {
        //         })
        //     }
        //   }
    },
    computed: {
        isUserDataLoaded() {
            // return this.$store.getters["AUTH/getIsUserDataLoaded"];
            return true;
        },

        isCompanySettingsCompleted() {
            // return this.$store.getters["COMPANY/getIsCompanySettingsCompleted"];
            return false;
        }
    },
    methods: {
        untoggleSidebar() {
            this.active = !this.active;
            // this.vm.sidebarToggled = !this.vm.sidebarToggled;
            document
                .getElementsByClassName("mini-mode")[0]
                .classList.remove("mini-mode");
            document
                .getElementsByTagName("body")[0]
                .classList.remove("mini-mode");
        }
    },
    async mounted() {
        // eventBus.$emit('displayInitialLoader', false);
        // this.$on("toggleNav", () => {
        //   this.active = !this.active;
        // });
    }
};
</script>

<style lang="scss">
// cards
// .card {
//     border: none;
//     border-radius: 5px;
//     overflow: hidden;
// }

// date blocks
// .date {
//     color: $white;
//     position: absolute;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     .month {
//         font-size: 30px;
//         line-height: 21px;
//         text-transform: uppercase;
//         font-size: 30px;
//         font-weight: 300;
//     }
//     .day {
//         opacity: 0.5;
//         font-size: 20px;
//         line-height: 22px;
//         display: inline-block;
//         font-weight: 200;
//         margin-bottom: 10px;
//     }
//     .dd-yyyy {
//         font-size: 24px;
//         font-weight: 500;
//         line-height: 22px;
//     }
// }
// Color Variations
// .blue {
//     .date {
//         background: $blue;
//         box-shadow: 0px 5px 14.3px 0.8px rgba(54, 171, 255, 0.2);
//     }
//     &.btn,
//     .btn,
//     .tag {
//         background: transparentize($blue, 0.8);
//         color: $blue;
//         &:hover {
//             background: transparentize($blue, 0.7);
//             @include blackShadow();
//         }
//     }
// }
// .pink {
//     .date {
//         background: $pink;
//         box-shadow: 0px 5px 14.3px 0.8px rgba(210, 54, 255, 0.2);
//     }
//     &.btn,
//     .btn,
//     .tag {
//         background: transparentize($pink, 0.8);
//         color: $pink;
//         &:hover {
//             background: transparentize($pink, 0.7);
//             @include blackShadow();

//         }
//     }
// }
// .orange {
//     .date {
//         background: $orange;
//         box-shadow: 0px 5px 14.3px 0.8px
//             transparentize($color: $orange, $amount: 0.8);
//     }
//     &.btn,
//     .btn,
//     .tag {
//         background: transparentize($orange, 0.8);
//         color: $orange;
//         &:hover {
//             background: transparentize($orange, 0.7);
//             @include blackShadow();

//         }
//     }
// }
// .darkOrange {
//     .date {
//         background: $darkOrange;
//         box-shadow: 0px 5px 14.3px 0.8px rgba(225, 54, 41, 0.2);
//     }
//     &.btn,
//     .btn,
//     .tag {
//         background: transparentize($darkOrange, 0.8);
//         color: $darkOrange;
//         &:hover {
//             background: transparentize($darkOrange, 0.7);
//             @include blackShadow();

//         }
//     }
// }
// .green {
//     .date {
//         background: $green;
//         box-shadow: 0px 5px 14.3px 0.8px
//             transparentize($color: $green, $amount: 0.8);
//     }
//     &.btn,
//     .btn,
//     .tag {
//         background: transparentize($green, 0.8);
//         color: $green;
//         &:hover {
//             background: transparentize($green, 0.7);
//             @include blackShadow();

//         }
//     }
// }
// .darkGreen {
//     .date {
//         background: $darkGreen;
//         box-shadow: 0px 5px 14.3px 0.8px rgba(34, 234, 57, 0.2);
//     }
//     &.btn,
//     .btn,
//     .tag {
//         background: transparentize($darkGreen, 0.8);
//         color: $darkGreen;
//         &:hover {
//             background: transparentize($darkGreen, 0.7);
//             @include blackShadow();

//         }
//     }
// }

.actions {
    display: flex;
}
.event-details {
    .btn {
        margin-top: 35px;
        margin-right: 20px;
        img {
            margin-right: 6px;
        }
    }
}
</style>
