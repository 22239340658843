const getters = {
    getIsEmployeeListsLoaded: (state) => {
        return state.isEmployeeListsLoaded;
    },

    getEmployeeLists: (state) => {
        return state.employeeLists;
    },

    getEmployeeListsByStatus: (state) => (status) => {
        return state.employeeLists.filter((x) => {
            return x.status === status;
        });
    },
};

export default getters;
