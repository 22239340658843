<template>
    <div class="popup-wrapper">
        <div class="popup are-you-sure flexible">
            <div class="backdrop"></div>
            <p :class="{ 'long-text': addLongTextClass }">{{ title }}</p>
            <div class="actions_wrapper">
                <button
                    href="javascript:"
                    class="btn btn-primary"
                    @click.prevent="yes"
                    :disabled="isDisabled"
                >
                    Yes
                </button>
                <button
                    href="javascript:"
                    class="btn btn-danger"
                    @click.prevent="close"
                >
                    Cancel
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { closePopup } from "./../../../surePopupMethods";
export default {
    components: {},
    data() {
        return {
            yes: this.sureConfirm,
            title:
                this.modalTitle || this.$store.getters["CONFIRMMODAL/getTitle"]
        };
    },
    props: ["type", "modalTitle", "data"],
    mounted() {
        document.getElementsByTagName("body")[0].classList.add("hide-overflow");
    },
    computed: {
        addLongTextClass() {
            if (this.title == "Are you sure ?") {
                return false;
            } else {
                return true;
            }
        },
        isDisabled() {
            return this.$store.getters["CONFIRMMODAL/getIsSetDisabled"];
        }
    },
    methods: {
        sureConfirm() {
            if (this.type === "surepopup") {
                this.data["value"] = "yes";
                this.$emit("sureconfirm", this.data);
            } else {
                this.$store.getters["CONFIRMMODAL/getConfirm"]();
            }
        },
        confirmation(value) {
            this.$store.getters["CONFIRMMODAL/getDisplay"];
        },
        close() {
            document
                .getElementsByTagName("body")[0]
                .classList.remove("hide-overflow");
            closePopup();
            this.$emit("closePopUp");
        }
    }
};
</script>
